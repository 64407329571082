import { AngularEditorConfig } from "@kolkov/angular-editor";
import { environment } from "../environments/environment";

export class Values {
  static Version = "8.5.70";
  static VersionDate = "22/01/2025";

  // TODO: Crear api para prod y poner restricción http con movin.cloud y http://movin.cloud con www también? probarlo. La de demo pues no poner restricción
  // Prod:
  // static GOOGLE_API_KEY = 'AIzaSyDDD_E7LB2dKMu2qJniasF0fMatAyfmaXE';
  // Demo:
  // static GOOGLE_API_KEY = "AIzaSyDDD_E7LB2dKMu2qJniasF0fMatAyfmaXE";

  // Stripe Public Key
  // Prod:
  static STRIPE_KEY = "pk_live_ppTZuY5XIGbYVQvW2a0hzHB300Xbci1Lne";
  // Dev:
  // static STRIPE_KEY = 'pk_test_8bH0KG7sCqjbbHXYm64AjJ1000XM7OHbzl';

  // LINK 4DMOVIN
  // Prod:
  // static ROOT_URL = 'https://movin.cloud';
  // Dev:
  static ROOT_URL = "https://test-erp.movin.cloud";
  // NICO:
  // static ROOT_URL = "http://10.105.0.1:8080";

  // LINK GROWTHTOOL360
  // Prod:
  // static ROOT_URL = 'https://app.growthtool360.com';
  // Dev:
  // static ROOT_URL = 'https://app.growthtool360.com';

  static ERP_URL = "/";

  static BASE_URL = Values.ROOT_URL + Values.ERP_URL;

  static MARKETING_PICS_DIR_URL = Values.BASE_URL + "marketing-images/";

  static SERVER_URL = Values.BASE_URL + "backend/index.php?resource=";

  // static SERVER_URL = Values.BASE_URL + "backendTestA/index.php?resource=" ;

  // static SERVER_URL = Values.BASE_URL + "backendTestB/index.php?resource=";

  // static SERVER_URL = Values.BASE_URL + "backendTestC/index.php?resource=";

  static SERVER_URL_CLIENT = Values.BASE_URL + "backend_client/index.php?resource=";

  static NO_IMAGE_URL = "assets/img/no-image.png";

  static CHAT_GROUP = "assets/img/default/logo-group.png";

  static CHAT_SERVER_URL = environment.SOCKET_ENDPOINT;

  static ROUTES = {
    CLIENTS_GENERIC: "listado-clientes",
    PROPERTIES_GENERIC: "listado-inmuebles",
    LOGIN: "login",
    INMO: "inmo",
    REGISTRY_INMO_GROUP: "registry-inmo-group",
    COMMUNITY: "community",
    CHAT: "chat",
  };

  static API_REQ_PATTERN = "&req=";

  static API_RESOURCES = {
    PROPERTIES: "properties",
    CLIENTS: "clients",
    SEARCH: "search",
    LOGIN: "login",
    USER: "user",
    PRIVILEGIES: "privilegies",
    LOCATIONS: "locations",
    ACTIVITIES: "activities",
    EVENTS: "events",
    EMAILTEMPLATES: "emailtemplates",
    USERMONITORING: "user-monitoring",
    SETTINGS: "settings",
    FILES: "files",
    INMO: "inmo",
    COMMON: "common",
    REPORT: "report",
    BILL: "bill",
    EGO_NOTIFICATIONS: "ego-notifications",
    MARKETPLACE: "marketplace",
    ORPHAN: "orphan",
    LANDING: "landing",
    GROUP: "group",
    COMMUNITY: "community",
    PORTAL: "portal",
    GENERIC: "generic",
    EMAILINMO: "emailInmo",
    EXPORTERS: "exporters",
    GOOGLE: "google",
    MICROSOFT: "microsoft",
    SOCIAL_MEDIA_TWITTER: "twitter",
    SOCIAL_MEDIA_INSTAGRAM: "instagram",
    SOCIAL_MEDIA_FACEBOOK: "facebook",
    SOCIAL_MEDIA_LINKEDIN: "linkedin",
    SOCIAL_MEDIA: "socialmedia",
    LOAD_DATA_FROM_FILE: "load-data-from-file",
    PAYMENT: "payment",
  };

  static LOAD_DATA_FROM_FILE = {
    LOAD_CLIENTS_FROM_FILE: "insert-load-data-from-file",
  };

  static INMO_REQS = {
    CREATE_INMO_DEMO: "create-inmo-demo",
    DELETE_IMAGE_INMO: "delete-image-inmo",
    REJECT_CANCEL_USER: "reject-cancel-user",
    GET_NAME_INMO_BY_USER_ID: "get-inmo-name-by-user-id",
    LINK_INMO_GROUP: "link-inmo-group",
    GET_ALL_INMO_WITHOUT_YOUR_GROUP: "get-all-inmo-without-your-group",
    CANCEL_INMO_ACCOUNT: "cancel-inmo-account",
    COUNT_ADMIN_INMO: "admin-count-inmo",
  };

  static GENERIC_REQUEST = {
    GET_MODULE_ID_BY_ROUTE: 'get-module-id-by-route',
    GET_ROUTE_BY_ID: 'get-route-by-id',
    RETRIEVE_STRUCTURE: 'retrieveStructure',
    REFRESH_STRUCTURE: 'refreshStructure',
    GET_ACTIVE_COMPANY: 'get-active-company',
    SET_ACTIVE_COMPANY: 'set-active-company',
    GET_DATA_BY_ID: 'get-data-by-id-query',
    UPDATE_RESULTS: 'update-results',
    INSERT_FORM_DATA: 'insert-form-data',
    INSERT_FORM_DATA_INFO: 'insert-form-data-info',
    PARSE_LANGUAGE_TAG: 'translate-tag',
    DELETE_DATA: 'delete-data',
    GET_TEXT_INMUEBLE: 'get-text-inmueble',
    SAVE_TEXT_INMUEBLE: 'save-text-inmueble',
    UPDATE_IMAGES_PROPERTY: 'update-images-property',
    GET_CONDITIONS_FROM_EVENTO: 'get_conditions_from_evento',
    GET_EVENTOS_TABLES: 'get_eventos_tables',
    GET_FIELDS_NAME_TABLE: 'get_fields_name_table',
    GET_OPERATORS_CONDITIONS: 'get_operators_conditions',
    UPDATE_CONDITIONS: 'update-conditions',
    DELETE_CONDITION: 'delete-condition',
    GET_ALL_PLANTILLAS_IDIOMA: 'get-all-plantillas-idioma',
    RELOAD_QUERIES: 'reload_queries',
    GET_ELEMENTS_GENERIC_CREATOR: 'get-elements-generic-creator',
    UPDATE_FA_CUSTOM: 'update-fa-custom',
    ARCHIVE_ALL_DEMANDA_FROM_VIVIENDA_CUSTOM: "archive-all-demandas-from-vivienda-custom",
    LOAD_DEFER_TABLE_DATA: "load-defer-table-data",
    RELOAD_TABLE: 'reload-table',
    CHANGE_COMPANY: 'change-company',
    DELETE_ALL_DOCS: 'delete-all-docs',
    SAVE_USER_PARAMS: 'save-user-params',
    SAVE_USER_PARAMS_FA: 'save-user-params-fa',
    GET_TABLES_FROM_DATABASE: "get-tables-from-database",
    GET_FIELDS_FROM_TABLE: "get-fields-from-table",
    SEARCH_GENERIC: "search-generic",
    GET_DB_SCHEMA: "get-db-schema",
    GET_DB_SCHEMA_WINDOW: "get-db-schema-window",
    GET_ORGANIZATION_CHART: "get-organization-chart",
    GET_MENU_GENERIC: "get-menu-generic",
    GET_CLIENT_SCRIPT_CODE: "get-client-script-code",
    SAVE_CONTROLLER_MODEL_CODE: "save-controller-model-code",
    GET_CONTROLLER_MODEL_CODE:"get-controller-model-code",
    SAVE_CLIENT_SCRIPT_CODE: "save-client-script-code",
    RUN_CLIENT_SCRIPT_CRON: "run-client-script-cron",
    SAVE_DELETED_FA_BY_USER: "save-deleted-fa-by-user",
    SAVE_DELETED_FA_BY_COMPANY: "save-deleted-fa-by-company"
  };

  static RESTAURANT_REQS = {
    INSERT_PEDIDO_STATIC_RESTAURANT: "insert-pedido-static-restaurant",
    MERGE_PEDIDOS_RESTAURANT: "merge-pedidos-restaurant",
    MOVE_PEDIDO_RESTAURANT: "move-pedido-restaurant",
    MARCHAR_PRODUCTOS_PEDIDO_RESTAURANT: "marchar-productos-pedido-restaurant",
    ANADIR_PRODUCTO_DIRECTO_RESTAURANT: "anadir-producto-directo-restaurant",
    PRODUCTOS_ESTADO_CINCO_DIRECTO: "productos-estado-cinco-directo",
    ADD_TO_CHECKOUT_RESTAURANT: "add-to-checkout-restaurant",
    GET_REST_MESAS: "get-rest-mesas",
    GET_REST_TICKET: "get-rest-ticket",
    GET_REST_COMANDA: "get-rest-comanda",
    GET_REST_COMANDA_CANCELADOS: "get-rest-comanda-cancelados",
    GET_REST_MOVIMIENTO: "get-rest-movimiento",
    GET_REST_CIERRE_CAJA: "get-rest-cierre-caja",
    GET_REST_CIERRE_CAJA_SNAPSHOT: "get-rest-cierre-caja-snapshot",
    GET_REST_PEDIDO: "get-rest-pedido",
    GET_REST_CASH_PRINTER: "get-rest-cash-printer",
    INSERT_TICKET_PEDIDO: "insert-ticket-pedido",
    CHECK_OCUPACION_MESA_RESERVAS: "check-ocupacion-mesa",
    CHECK_OCUPACION_UBI_RESERVAS: "check-ocupacion-ubi",
  };

  static REPORT_REQS = {
    GET_PLANTILLA: "get-plantilla",
    ACCEPT_CONTRACT: "accept-contract",
    ACCEPT_PRIVACITY: "accept-privacity",
    MAILCHIMP: "mailchimp",
    NEW_NOTI_EGO: "new-not-ego",
  };

  static PAYMENT_REQS = {
    PAY: "pay",
    UPDATE_PAYMENT: "upate-payment",
    MAKE_PAYMENT: "make-payment",
    INSERT_TICKET_PAYMENT_METHOD: "insert-ticket-payment-method"
  };

  static COMMUNITY_REQS = {
    ACCEPT_COLLABORATION: "accept-collaboration",
    REJECT_COLLABORATION: "reject-collaboration",
    GET_CHAT_CONTACTS: "get-chat-contacts",
    UPLOAD_CHAT_GROUP_PICTURE: "upload-chat-group-picture",
    UPDATE_CHAT_GROUP_PICTURE: "update-chat-group-picture",
    UPDATE_COLABORACION: "update-colaboracion",
    ACCEPT_INMO_GROUP_REQUEST: "accept-inmo-group-request",
    REJECT_INMO_GROUP_REQUEST: "reject-inmo-group-request",
    ACCEPT_INMO_GROUP_INVITATION: "accept-inmo-group-invitation",
    REJECT_INMO_GROUP_INVITATION: "reject-inmo-group-invitation",
    ACCEPT_USER_GROUP_REQUEST: "accept-user-group-request",
    REJECT_USER_GROUP_REQUEST: "reject-user-group-request",
    ACCEPT_USER_GROUP_INVITATION: "accept-user-group-invitation",
    REJECT_USER_GROUP_INVITATION: "reject-user-group-invitation",
    INMO_LEAVE_GROUP: "inmo-leave-group",
    USER_LEAVE_GROUP: "user-leave-group",
    GET_USER_CHAT_INFO: "get-user-chat-info",
    GET_ROOM_INFO: "get-room-info",
    GET_COMMUNITY_ADMINS_FOR_CHAT_INMO_GROUP:
      "get-community-admins-for-chat-inmo-group",
  };

  static BILL_REQS = {
    GET_PLANES_MOVIN: "get-planes-movin",
    GET_PLANES_COMPRADOS: "get-planes-comprados",
    GET_PLANES_USADOS: "get-planes-usados",
    SET_PLANES_MOVIN: "set-planes-movin",
    UNSUBSCRIBE: "unsubscribe",
    TOTAL: "total",
    RESTAR_MOVINS: "restar-movins",
  };

  static USER_MONITORING_REQS = {
    INSERT: "insert-data",
    UPDATE: "update-data",
    INSERT_FUNCTIONS: "insert-functions-data",
  };

  static USER_REQS = {
    GET_USER_INFO: "get-user-info",
    UPLOAD_USER_PROFILE_PICTURE: "upload-user-profile-picture",
    GET_USERS: "get-users",
    GET_ALL_FROM_USER: "get-all-from-user",
    INSERT_USER: "insert-update-user",
    UPDATE_PROFILE_USER: "update-profile-user",
    UPDATE_AGENDA_SETTINGS: "update-agenda-settings",
    UPDATE_MATCHING_PASSWORD: "update-matching-password",
    CHECK_USER_EXISTS: "check-user-exists",
    DELETE_USER_REPORT: "delete-user-report",
    UPDATE_USER_REPORT_VALUES: "update-user-report-values",
    UPDATE_USER_REPORT_COMISSION: "update-user-report-comission",
    MIGRATE_CLIENTS: "migrate-clients",
    GET_ALL_MY_ACCOUNTS_USERS: "get-all-my-accounts-users",
    INSERT_SCHEDULE_VIEW: "insert-schedule-view",
    DELETE_IMAGE_PROFILE: "delete-image-profile",
    UPDATE_DEFAULT_EMPRESA_CALENDARIO_USUARIO: "update-default-empresa-calendario-usuario",
    GET_PIN_WAITER: "get-pin-waiter"
  };

  static LOGIN_REQS = {
    TRY_LOGIN: "try-login",
    CANCEL_GROUP_ACCOUNT: "cancel-group-account",
    VALIDTE_USER: "validate-user",
    RECOVER_PASSWORD: "recover-password",
    CHECK_CODE: "check-code",
    CHANGE_PASSWORD: "change-password",
   }

  static LOCATION_REQS = {
    GET_LOCATIONS: "get-locations",
  };

  static PROPERTIES_REQS = {
    GET_ACTIVITIESPREDEFINEDVALUES: "get-values",
    DUPLICATE_VIVIENDA: "duplicate-vivienda",
    DELETE_ALL_PICTURES: "delete-all-pictures",
    DELETE_UNPUBLISHED_PICTURES: "delete-unpublished-pictures",
  };

  static ACTIVITIES_REQS = {
    GET_ACTIVITIESPREDEFINEDVALUES: "get-values",
    CREATE_ACTIVITY: "create-activity",
    EDIT_ACTIVITY: "edit-activity",
    GET_ACTIVITIES: "get-activities",
    GET_ACTIVITY: "get-activity",
    DELETE_ACTIVITY_ADMIN: "delete-activity-admin",
    GET_REFERENCE_ACTIVITIES: "get-reference-activities",
    GET_ONEACTIVITIY: "get-one-activity",
    GET_CALENDAR_ACTIVITY: "get-calendar-activity",
    DELETE_CALENDAR_PERIOD_ACTIVITY: "delete-calendar-period-activity",
    GET_ALL_GROUP_USERS_BY_ID: "get-all-group-users-by-id",
    CHECK_USER_GROUP_NAME_EXISTS: "check-user-group-name-exists",
  };

  static CLIENTS_REQS = {
    DUPLICATE_MATCHING: "duplicate-matching",
  };

  static SEARCH_REQS = {
    FILTER_INMO: "filter-inmo",
    FILTER_INMO_GROUP: "filter-inmo-group",
  };

  static MARKETPLACE_REQS = {
    TEST: "test-marketplace",
    GET_MODULOS_OFERTAS: "get-modulos-ofertas",
    GET_PACKS: "get-packs",
    GET_MODULOS_PACKS: "get-modulos-packs",
    GET_MODULOS_INMO: "get-modulos-inmo",
    TIENE_MODULO_DEPENDIENTE: "tiene-modulo-dependiente",
    GET_MOVINS: "get-movins",
    INSERT_MODULO_INMO: "insert-modulo-inmo",
    DESACTIVAR_MODULO_INMO: "desactivar-modulo-inmo",
    GET_MODULOS_DEPENDIENTES: "get-modulos-dependientes",
    INSERT_CONFIG_PRODUCT: "insert-config-product",
    GET_PLANTILLA_EGO_BUTTON_APP: "get-plantilla-ego-button-app",
    INSERT_PLANTILLA_EGO_BUTTON_APP_GENERIC_PRODUCT:
      "insert-plantilla-ego-button-app-generic-product",
    GET_ID_PLANTILLA_EGO_BUTTON_APP_GENERIC_PRODUCT:
      "get-id-plantilla-ego-button-app-generic-product",
    SET_DEMO_REALIZADA: "set-demo-realizada",
    GET_DEMOS_INFO: "get-demos-info",
  };

  static EVENTS_REQS = {
    LOAD_PREDEFINED_VALUES: "load-predefined-values",
    LOAD_EXTERN_VALUES: "load-extern-values",
    QUEUE_EMAIL: "queue-email",
    CREATE_DYNAMIC_EGO_NOTIFICATION: "create-dynamic-ego-notification",
  };

  static EMAILTEMPLATES_REQS = {
    INSERT_EMAIL_IMAGE: "insert-email-image",
    SEND_CONTACT_MAIL: "send-contact-mail",
    INSERT_CONTACT_INFO: "insert-contact-info",
    GET_TEMPLETE_BY_ID_MODULE: "get-templete-by-id-module",
    UPLOAD_TEMPLETE_IMAGE: "upload-templete-image",
    DELETE_TEMPLETE_IMAGE: "delete-templete-image",
  };


  static COMMON_REQS = {
    GET_PORTAL_ACTIVITIES_AVAILABILITY: "get-portal-activities-availability",
    GET_ALL_COMMERCIAL_AVAILABLE: "get-all-commercial-available",
    COMMERCIAL_ACCEPTED_VISIT: "commercial-accepted-visit",
    COMMERCIAL_REJECTED_VISIT: "commercial-rejected-visit",
    GET_COMMERCIAL_NOTIFICATIONS: "get-commercial-notifications",
    GET_ACTIVITIES_NOTIFICATIONS: "get-activities-notifications",
    DELETE_NOTIFICATION: "delete-notification",
    GET_PROPERTY_INFO: "get-property-info",
    GET_ADMIN_AREA_BY_INMO: "get-admin-area-by-inmo",
  };

  static EGONOTIFICATIONS_REQS = {
    GET_EGO_NOTIFICATION: "get-ego-notification",
    GET_EGO_HOME_NOTIFICATION: "get-ego-home-notification",
    GET_EGO_SUPERIOR_NOTIFICATION: "get-ego-superior-notification",
    GET_QUESTIONS: "get-questions",
    INSERT_EGO_ACTION: "insert-ego-action",
    DELETE_EGO_NOTIFICATION: "delete-ego-notification",
    INSERT_EGO_IMAGE: "insert-ego-image",
    DELETE_IMAGE_EGO: "delete-image-ego",
    GET_EGO_NOTIFICATION_BY_ID: "get-ego-notification-by-id",
    UPDATE_EGO_NOTIFICATION_FINAL_HOUR: "update_ego_notification_final_hour",
    INSERT_EGO_MONITOREO: "insert_ego_monitoreo",
    CLICK_EGO_MONITOREO: "click_ego_monitoreo",
  };

  static LANDING_REQS = {
    GET_INFO_INMO: "get-info-inmo"
  };

  static PORTAL_REQS = {
    GET_PORTALS_BY_INMO_ID: "get-portals-by-inmo-id",
    GET_VIVIENDAS_INMO_BY_PORTAL_ID: "get-viviendas-inmo-by-portal-id",
    GET_INMO_PORTAL: "get-inmo-portal"
  };

  static GOOGLE_REQS = {
    VERIFY_ACCOUNT: "verifyAccount",
    GET_CALENDARS: "get-calendars",
    VINCULAR_GOOGLE_CALENDAR: "vincular-google-calendar",
    SET_CALENDARS_GOOGLE: "set-calendars",
    UNSET_ALL_CALENDARS: "unset-all-calendars-google",
    DESVINCULAR_CALENDAR_GOOGLE: "desvincular-google-calendar",
    VINCULAR_GOOGLE_GMAIL: "vincular-google-gmail",
    DESVINCULAR_GOOGLE_GMAIL: "desvincular-google-gmail",
  };
  static MICROSOFT_REQS = {
    NEW_EVENT_CALENDAR_MICROSOFT: "new-calendar-event",
    GET_CALENDARS: "get-calendars",
    VINCULAR_MICROSOFT_CALENDAR: "vincular-microsoft-calendar",
    DESVINCULAR_MICROSOFT_CALENDAR: "desvincular-microsoft-calendar",
    SET_MICROSOFT_CALENDARS: "set-calendars",
    UNSET_ALL_CALENDARS: "unset-all-calendars-microsoft",
  };

  static HOTELS_REQS = {
    DUPLICATE_ACCOMMODATION: "duplicate-accommodation",
  };

  static MARKETPLACE_TYPES: { name: string; type: number; main: number }[] = [
    { name: "Mis módulos", type: 1, main: 1 },
    { name: "Todos los módulos", type: 2, main: 1 },
    { name: "Próximamente", type: 12, main: 1 },
    // {name: 'Packs', type: 3, main: 1}
  ];

  static EDITOR_CONFIG: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: "",
    defaultFontName: "Arial",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "top",
  };

  static COLABORATION_REQUEST: {
    inmoSolicitante: number;
    inmoSolicitada: number;
    idUsuario: number;
    estado: number;
    esPrivada: number;
  } = null;
}
