import { EventEmitter, Injectable, Output } from "@angular/core";

// Serivce
import { RoutingService } from "../services/routing/routing.service";
import { EndpointClientService } from "./endpoint-client.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { JourneyService } from 'app/services/journey/journey.service';
import { RedsysService } from 'app/services/redsys.service';
import { PaycometService } from 'app/services/paycomet.service';
import { PrintTicketService } from 'app/services/generic/custom/print-ticket.service';


// Component
import { AuthService } from "../services/auth/auth.service";
import { SideNavService } from "app/utils/SideNavService";

import { DemoDialogComponent } from "app/demo-dialog/demo-dialog.component";
import { FunctionsService } from "app/services/generic/functions.service";
import { GenericClientService } from "./custom-services-client/generic-client.service";


@Injectable({
  providedIn: "root",
})
export class FunctionsClientService {

  public checkoutUrl = "checkout-payment-cart";
  public headerId;
  public headerNoLoguedId;
  public footerId;
  public footerNoLoguedId;
  public formsChanged;
  public currentInitialArea;
  public paramControlVariables;
  public selectOptions;
  public snackbarOpened;
  public staticHTML;
  public selectOptionsChange;
  public selectOptionsChangeEvent;
  public selectOptionsHeader;
  public selectOptionsFooter;

  @Output() update_category: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() openNoti: EventEmitter<any> = new EventEmitter();
  //@ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  constructor(
    private routingService: RoutingService,
    private endpointClientService: EndpointClientService,
    private authService: AuthService,
    private sidenavService: SideNavService,
    private functionsService: FunctionsService,
    public templateService: TemplateService,
    public journeyService: JourneyService,
    public redsysService: RedsysService,
    public paycometService: PaycometService,
    public printTicketService: PrintTicketService,
    public genericClientService: GenericClientService
  ) { }

  public executeFunction(id, structure, param, finished, isFromField) {
    this.headerId = this.functionsService.getHeaderId();
    this.headerNoLoguedId = this.functionsService.getHeaderNoLoguedId();
    this.footerId = this.functionsService.getFooterId();
    this.footerNoLoguedId = this.functionsService.getFooterNoLoguedId();
    this.formsChanged = this.functionsService.getFormsChanged();
    this.currentInitialArea = this.functionsService.getCurrentInitialArea();
    this.paramControlVariables = this.functionsService.getParamControlVariables();
    this.selectOptions = this.functionsService.getSelectOptions();
    this.snackbarOpened = this.functionsService.getSnackbarOpened();
    this.staticHTML = this.functionsService.getStaticHTML();
    this.selectOptionsChange = this.functionsService.getSelectOptionsChange();
    this.selectOptionsChangeEvent = this.functionsService.getSelectOptionsChangeEvent();
    this.selectOptionsHeader = this.functionsService.getSelectOptionsHeader();
    this.selectOptionsFooter = this.functionsService.getSelectOptionsFooter();

    switch (id) {
      case 1:
        this.functionCase1(structure, param, finished);
        break;
      case 2:
        this.functionCase2(structure, param, finished);
        break;
      case 3:
        this.functionCase3(structure, param, finished);
        break;
      case 4:
        this.functionCase4(structure, param, finished);
        break;
      case 5:
        this.functionCase5(structure, param, finished);
        break;
      case 6:
        this.functionCase6(structure, param, finished);
        break;
      case 7:
        this.functionCase7(structure, param, finished);
        break;
      case 8:
        this.functionCase8(structure, param, finished);
        break;
      case 9:
        this.functionCase9(structure, param, finished);
        break;
      case 10:
        this.functionCase10(structure, param, finished);
        break;
      case 11:
        this.functionCase11(structure, param, finished);
        break;
      case 12:
        this.functionCase12(structure, param, finished);
        break;
      case 13:
        this.functionCase13(structure, param, finished);
        break;
      case 14:
        this.functionCase14(structure, param, finished);
        break;
      case 15:
        this.functionCase15(structure, param, finished);
        break;
      case 17:
        this.functionCase17(structure, param, finished);
        break;
      case 18:
        this.functionCase18(structure, param, finished);
        break;
      case 19:
        this.functionCase19(structure, param, finished);
        break;
      case 20:
        this.functionCase20(structure, param, finished);
        break;
      case 22:
        this.functionCase22(structure, param, finished);
        break;
      case 23:
        this.functionCase23(structure, param, finished);
        break;
      case 24:
        this.functionCase24(structure, param, finished);
        break;
      case 25:
        this.functionCase25(structure, param, finished);
        break;
      case 26:
        this.functionCase26(structure, param, finished);
        break;
      case 27:
        this.functionCase27(structure, param, finished);
        break;
      case 33:
        this.functionCase33(structure, param, finished);
        break;
      case 34:
        this.functionCase34(structure, param, finished);
        break;
      case 35:
        this.functionCase35(structure, param, finished);
        break;
      case 36:
        this.functionCase36(structure, param, finished);
        break;
      case 37:
        this.functionCase37(structure, param, finished);
        break;
      case 38:
        this.functionCase38(structure, param, finished);
        break;
      case 39:
        this.functionCase39(structure, param, finished);
        break;
      case 40:
        this.functionCase40(structure, param, finished);
        break;
      case 41:
        this.functionCase41(structure, param, finished);
        break;
      case 42:
        this.functionCase42(structure, param, finished);
        break;
      case 43:
        this.functionCase43(structure, param, finished);
        break;
      case 44:
        this.functionCase44(structure, param, finished);
        break;
      case 45:
        this.functionCase45(structure, param, finished);
        break;
      case 46:
        this.functionCase46(structure, param, finished);
        break;
      case 47:
        this.functionCase47(structure, param, finished);
        break;
      case 48:
        this.functionCase48(structure, param, finished);
        break;
      case 49:
        this.functionCase49(structure, param, finished);
        break;
      case 50:
        this.functionCase50(structure, param, finished);
        break;
      case 51:
        this.functionCase51(structure, param, finished);
        break;
      case 52:
        this.functionCase52(structure, param, finished);
        break;
      case 53:
        this.functionCase53(structure, param, finished);
        break;
      case 54:
        this.functionCase54(structure, param, finished);
        break;
      case 55:
        this.functionCase55(structure, param, finished);
        break;
      case 56:
        this.functionCase56(structure, param, finished);
        break;
      case 57:
        this.functionCase57(structure, param, finished);
        break;
      case 58:
        this.functionCase58(structure, param, finished);
        break;
      case 59:
        this.functionCase59(structure, param, finished);
        break;
      case 60:
        this.functionCase60(structure, param, finished);
        break;
      case 62:
        this.functionCase62(structure, param, finished);
        break;
      case 63:
        this.functionCase63(structure, param, finished);
        break;
      case 64:
        this.functionCase64(structure, param, finished);
        break;
      case 65:
        this.functionCase65(structure, param, finished);
        break;
      case 66:
        this.functionCase66(structure, param, finished);
        break;
      case 67:
        this.functionCase67(structure, param, finished);
        break;
      case 68:
        this.functionCase68(structure, param, finished);
        break;
      case 70:
        this.functionCase70(structure, param, finished);
        break;
      case 71:
        this.functionCase71(structure, param, finished);
        break;
      case 72:
        this.functionCase72(structure, param, finished);
        break;
      case 82:
        this.functionCase82(structure, param, finished);
        break;
      case 84:
        this.functionCase84(structure, param, finished);
        break;
      case 85:
        this.functionCase85(structure, param, finished);
        break;
      case 86:
        this.functionCase86(structure, param, finished);
        break;
      case 87:
        this.functionCase87(structure, param, finished);
        break;
      case 88:
        this.functionCase88(structure, param, finished);
        break;
      case 89:
        this.functionCase89(structure, param, finished);
        break;
      case 90:
        this.functionCase90(structure, param, finished);
        break;
      case 91:
        this.functionCase91(structure, param, finished);
        break;
      case 93:
        this.functionCase93(structure, param, finished);
        break;
      case 94:
        this.functionCase94(structure, param, finished);
        break;
      case 95:
        this.functionCase95(structure, param, finished);
        break;
      case 96:
        this.functionCase96(structure, param, finished);
        break;
      case 97:
        this.functionCase97(structure, param, finished);
        break;
      case 98:
        this.functionCase98(structure, param, finished);
        break;
      case 99:
        this.functionCase99(structure, param, finished);
        break;
      case 100:
        this.functionCase100(structure, param, finished);
        break;
      case 101:
        this.functionCase101(structure, param, finished);
        break;
      case 102:
        this.functionCase102(structure, param, finished);
        break;
      case 103:
        this.functionCase103(structure, param, finished);
        break;
      case 104:
        this.functionCase104(structure, param, finished);
        break;
      case 105:
        this.functionCase105(structure, param, finished);
        break;
      case 106:
        this.functionCase106(structure, param, finished);
        break;
      case 109:
        this.functionCase109(structure, param, finished);
        break;
      case 110:
        this.functionCase110(structure, param, finished);
        break;
      case 112:
        this.functionCase112(structure, param, finished);
        break;
      case 113:
        this.functionCase113(structure, param, finished);
        break;
      case 114:
        this.functionCase114(structure, param, finished);
        break;
      case 115:
        this.functionCase115(structure, param, finished);
        break;
      case 116:
        this.functionCase116(structure, param, finished);
        break;
      case 117:
        this.functionCase117(structure, param, finished);
        break;
      case 118:
        this.functionCase118(structure, param, finished);
        break;
      case 119:
        this.functionCase119(structure, param, finished);
        break;
      case 120: // Pago-aceptado
        this.functionCase120(structure, param, finished);
        break;
      case 121: // Pago-rechazado
        this.functionCase121(structure, param, finished);
        break;
      case 122: // Volver a la página de pago
        this.functionCase122(structure, param, finished);
        break;
      case 123:
        this.functionCase123(structure, param, finished);
        break;
      case 124:
        this.functionCase124(structure, param, finished);
        break;
      case 125:
        this.functionCase125(structure, param, finished);
        break;
      case 126:
        this.functionCase126(structure, param, finished);
        break;
      case 127: //Realizar Pago
        this.functionCase127(structure, param, finished);
        break;
      case 128:
        this.functionCase128(structure, param, finished);
        break;
      case 129:
        this.functionCase129(structure, param, finished);
        break;
      case 130:
        this.functionCase130(structure, param, finished);
        break;
      case 131:
        this.functionCase131(structure, param, finished);
        break;
      case 132:
        this.functionCase132(structure, param, finished);
        break;
      case 133:
        this.functionCase133(structure, param, finished);
        break;
      case 135:
        this.functionCase135(structure, param, finished);
        break;
      case 137:
        this.functionCase137(structure, param, finished);
        break;
      case 138:
        this.functionCase138(structure, param, finished);
        break;
      case 139:
        this.functionCase139(structure, param, finished);
        break;
      case 141:
        this.functionCase141(structure, param, finished);
        break;
      case 142:
        this.functionCase142(structure, param, finished);
        break;
      case 143:
        this.functionCase143(structure, param, finished);
        break;
      case 144:
        this.functionCase144(structure, param, finished);
        break;
      case 145:
        this.functionCase145(structure, param, finished);
        break;
      case 146:
        this.functionCase146(structure, param, finished);
        break;
      case 147:
        this.functionCase147(structure, param, finished);
        break;
      case 148:
        this.functionCase148(structure, param, finished);
        break;
      case 149:
        this.functionCase149(structure, param, finished);
        break;
      case 150:
        this.functionCase150(structure, param, finished);
        break;
      case 151:
        this.functionCase151(structure, param, finished);
        break;
      case 152:
        this.functionCase152(structure, param, finished);
        break;
      case 153:
        this.functionCase153(structure, param, finished);
        break;
      case 154:
        this.functionCase154(structure, param, finished);
        break;
      case 155:
        this.functionCase155(structure, param, finished);
        break;
      case 156:
        this.functionCase156(structure, param, finished);
        break;
      case 157:
        this.functionCase157(structure, param, finished);
        break;
      case 162:
        this.functionCase162(structure, param, finished);
        break;
      case 163:
        this.functionCase163(structure, param, finished);
        break;
      case 164:
        this.functionCase164(structure, param, finished);
        break;
      case 165:
        this.functionCase165(structure, param, finished);
        break;
      case 166:
        this.functionCase166(structure, param, finished);
        break;
      case 168:
        this.functionCase168(structure, param, finished);
        break;
      case 169:
        this.functionCase169(structure, param, finished);
        break;
      case 170:
        this.functionCase170(structure, param, finished);
        break;
      case 171:
        this.functionCase171(structure, param, finished);
        break;
      case 173:
        this.functionCase173(structure, param, finished);
        break;
      case 174:
        this.functionCase174(structure, param, finished);
        break;
      case 175:
        this.functionCase175(structure, param, finished);
        break;
      case 176:
        this.functionCase176(structure, param, finished);
        break;
      case 178:
        this.functionCase178(structure, param, finished);
        break;
      case 179:
        this.functionCase179(structure, param, finished);
        break;
      case 180:
        this.functionCase180(structure, param, finished);
        break;
      case 181:
        this.functionCase181(structure, param, finished);
        break;
      case 182:
        this.functionCase182(structure, param, finished);
        break;
      case 183:
        this.functionCase183(structure, param, finished);
        break;
      case 185:
        this.functionCase185(structure, param, finished);
        break;
      case 186:
        this.functionCase186(structure, param, finished);
        break;
      case 187:
        this.functionCase187(structure, param, finished);
        break;
      case 188:
        this.functionCase188(structure, param, finished);
        break;
      case 189:
        this.functionCase189(structure, param, finished);
        break;
      case 190:
        this.functionCase190(structure, param, finished);
        break;
      case 191:
        this.functionCase191(structure, param, finished);
        break;
      case 192:
        this.functionCase192(structure, param, finished);
        break;
      case 193:
        this.functionCase193(structure, param, finished);
        break;
      case 194:
        this.functionCase194(structure, param, finished);
        break;
      case 195:
        this.functionCase195(structure, param, finished);
        break;
      case 196:
        this.functionCase196(structure, param, finished);
        break;
      case 197:
        this.functionCase197(structure, param, finished);
        break;
      case 198:
        this.functionCase198(structure, param, finished);
        break;
      case 199:
        this.functionCase199(structure, param, finished);
        break;
      case 200:
        this.functionCase200(structure, param, finished);
        break;
      case 201:
        this.functionCase201(structure, param, finished);
        break;
      case 202:
        this.functionCase202(structure, param, finished);
        break;
      case 203:
        this.functionCase203(structure, param, finished);
        break;
      case 204:
        this.functionCase204(structure, param, finished);
        break;
      case 205:
        this.functionCase205(structure, param, finished);
        break;
      case 206:
        this.functionCase206(structure, param, finished);
        break;
      case 207:
        this.functionCase207(structure, param, finished);
        break;
      case 208:
        this.functionCase208(structure, param, finished);
        break;
      case 209:
        this.functionCase209(structure, param, finished);
        break;
      case 210:
        this.functionCase210(structure, param, finished);
        break;
      case 212:
        this.functionCase212(structure, param, finished);
        break;
      case 213:
        this.functionCase213(structure, param, finished);
        break;
      case 214:
        this.functionCase214(structure, param, finished);
        break;
      case 215:
        this.functionCase215(structure, param, finished);
        break;
      case 216:
        this.functionCase216(structure, param, finished);
        break;
      case 217:
        this.functionCase217(structure, param, finished);
        break;
      case 218:
        this.functionCase218(structure, param, finished);
        break;
      case 219:
        this.functionCase219(structure, param, finished);
        break;
      case 220:
        this.functionCase220(structure, param, finished);
        break;
      case 222:
        this.functionCase222(structure, param, finished);
        break;
      case 224:
        this.functionCase224(structure, param, finished);
        break;
      case 225:
        this.functionCase225(structure, param, finished);
        break;
      case 226:
        this.functionCase226(structure, param, finished);
        break;
      case 227:
        this.functionCase227(structure, param, finished);
        break;
      case 228:
        this.functionCase228(structure, param, finished);
        break;
      case 229:
        this.functionCase229(structure, param, finished);
        break;
      case 230:
        this.functionCase230(structure, param, finished);
        break;
      case 231:
        this.functionCase231(structure, param, finished);
        break;
      case 232:
        this.functionCase232(structure, param, finished);
        break;
      case 233:
        this.functionCase233(structure, param, finished);
        break;
      case 234:
        this.functionCase234(structure, param, finished);
        break;
      case 235:
        this.functionCase235(structure, param, finished);
        break;
      case 236:
        this.functionCase236(structure, param, finished);
        break;
      case 237:
        this.functionCase237(structure, param, finished);
        break;
      case 238:
        this.functionCase238(structure, param, finished);
        break;
      case 239:
        this.functionCase239(structure, param, finished);
        break;
      case 240:
        this.functionCase240(structure, param, finished);
        break;
      case 241:
        this.functionCase241(structure, param, finished);
        break;
      case 242:
        this.functionCase242(structure, param, finished);
        break;
      case 243:
        this.functionCase243(structure, param, finished);
        break;
      case 244:
        this.functionCase244(structure, param, finished);
        break;
      case 245:
        this.functionCase245(structure, param, finished);
        break;
      case 246:
        this.functionCase246(structure, param, finished);
        break;
      case 247:
        this.functionCase247(structure, param, finished);
        break;
      case 248:
        this.functionCase248(structure, param, finished);
        break;
      case 249:
        this.functionCase249(structure, param, finished);
        break;
      case 250:
        this.functionCase250(structure, param, finished);
        break;
      case 251:
        this.functionCase251(structure, param, finished);
        break;
      case 252:
        this.functionCase252(structure, param, finished);
        break;
      case 253:
        this.functionCase253(structure, param, finished);
        break;
      case 254:
        this.functionCase254(structure, param, finished);
        break;
      case 255:
        this.functionCase255(structure, param, finished);
        break;
      case 257:
        this.functionCase257(structure, param, finished);
        break;
      case 262:
        this.functionCase262(structure, param, finished);
        break;
      case 267:
        this.functionCase267(structure, param, finished);
        break;
      case 268:
        this.functionCase268(structure, param, finished);
        break;
      case 269:
        this.functionCase269(structure, param, finished);
        break;
      case 270:
        this.functionCase270(structure, param, finished);
        break;
      case 272:
        this.functionCase272(structure, param, finished);
        break;
      case 273:
        this.functionCase273(structure, param, finished);
        break;
      case 274:
        this.functionCase274(structure, param, finished);
        break;
      case 275:
        this.functionCase275(structure, param, finished);
        break;
      case 276:
        this.functionCase276(structure, param, finished);
        break;
      case 277:
        this.functionCase277(structure, param, finished);
        break;
      case 279:
        this.functionCase279(structure, param, finished);
        break;
      case 280:
        this.functionCase280(structure, param, finished);
        break;
      case 281:
        this.functionCase281(structure, param, finished);
        break;
      case 282:
        this.functionCase282(structure, param, finished);
        break;
      case 283:
        this.functionCase283(structure, param, finished);
        break;
      case 284:
        this.functionCase284(structure, param, finished);
        break;
      case 285:
        this.functionCase285(structure, param, finished);
        break;
      case 286:
        this.functionCase286(structure, param, finished);
        break;
      case 287:
        this.functionCase287(structure, param, finished);
        break;
      case 288:
        this.functionCase288(structure, param, finished);
        break;
      case 289:
        this.functionCase289(structure, param, finished);
        break;
      case 290:
        this.functionCase290(structure, param, finished);
        break;
      case 291:
        this.functionCase291(structure, param, finished);
        break;
      case 292:
        this.functionCase292(structure, param, finished);
        break;
      case 293:
        this.functionCase293(structure, param, finished);
        break;
      case 295:
        this.functionCase295(structure, param, finished);
        break;
      case 296:
        this.functionCase296(structure, param, finished);
        break;
      case 297:
        this.functionCase297(structure, param, finished);
        break;
      case 298:
        this.functionCase298(structure, param, finished);
        break;
      case 299:
        this.functionCase299(structure, param, finished);
        break;
      case 300:
        this.functionCase300(structure, param, finished);
        break;
      case 301:
        this.functionCase301(structure, param, finished);
        break;
      case 302:
        this.functionCase302(structure, param, finished);
        break;
      case 303:
        this.functionCase303(structure, param, finished);
        break;
      case 304:
        this.functionCase304(structure, param, finished);
        break;
      case 305:
        this.functionCase305(structure, param, finished);
        break;
      case 306:
        this.functionCase306(structure, param, finished);
        break;
      case 307:
        this.functionCase307(structure, param, finished);
        break; 
      case 308:
        this.functionCase308(structure, param, finished);
        break;
      case 309:
        this.functionCase309(structure, param, finished);
        break;
      case 310:
        this.functionCase310(structure, param, finished);
        break;
      case 311:
        this.functionCase311(structure, param, finished);
        break;
      case 312:
        this.functionCase312(structure, param, finished);
        break;
      case 313:
        this.functionCase313(structure, param, finished);
        break;
      case 314:
        this.functionCase314(structure, param, finished);
        break;
      case 316:
        this.functionCase316(structure, param, finished);
        break;
      case 317:
        this.functionCase317(structure, param, finished);
        break;
      case 318:
        this.functionCase318(structure, param, finished);
      case 782:
        this.functionCase782(structure, param, finished);
        break;
      case 783:
        this.functionCase783(structure, param, finished);
        break;
      }
  }  

  public functionCase1(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      if (!structure["no_save_params"]) this.functionsService.saveParams(structure, param, structure["id_functional_parent_initial"]);
      this.functionsService.route = structure["internal_routing"];
      this.functionsService.id_route = structure["internal_routing_id_functional_area"];
      this.routingService.previousModule = structure["id_functional_parent_initial"];
      if(structure['isPressingControl']) {
        this.functionsService.openInExteranlURL(this.functionsService.route);
      } else {
        this.functionsService.goRouting(this.functionsService.route, true);
      }
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase2(structure, param, finished) {
    if (structure && structure["id_functional_parent_initial_dsb"] == 0 && !structure["paramsAdded"]) {
      this.functionsService.getInternParam(structure, param);
    }
    this.functionsService.openDialog(structure, 1);
    this.functionsService.finishFunction(finished);
  }

  public functionCase3(structure, param, finished) {
    this.functionsService.dialogRef.close(structure, param);
    this.functionsService.finishFunction(finished);
  }

  public functionCase4(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, finished);
    if (validReq) {
      this.functionsService.duplicateElementUnified(structure, finished, true, 1);
      this.functionsService.dialogRef.close(structure, finished);
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase5(structure, param, finished) {
    if (structure["id_functional_parent_initial_dsb"] == 0 && !structure["paramsAdded"]) {
      this.functionsService.getInternParam(structure, param);
    }
    this.functionsService.openDialog(structure, 2);
  }

  public functionCase6(structure, param, finished) {
    this.functionsService.generateNewOutputParam(structure);
    this.functionsService.updateParamControl();
    this.functionsService.route = structure["internal_routing"];
    this.functionsService.id_route = structure["internal_routing_id_functional_area"];

    this.routingService.previousModule =
      structure["id_functional_parent_initial"];
    if (param && param["tmp_param"]) {
      this.routingService.routeParam = null;
    }
    this.functionsService.goRouting(this.functionsService.route, true);
    this.functionsService.finishFunction(finished);
  }

  public functionCase7(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
  }

  public functionCase8(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 1, false, false);
  }

  public functionCase9(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, false, false);
  }

  public functionCase10(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, false, true, 1);
  }

  public functionCase11(structure, param, finished) {
    this.functionsService.duplicateElementUnified(structure, finished);
    this.functionsService.finishFunction(finished);
  }

  public functionCase12(structure, param, finished) {
    this.functionsService.openDeferContainer(param, structure);
    this.functionsService.finishFunction(finished);
  }

  public functionCase13(structure, param, finished) {
    this.sidenavService.open(0);
    this.functionsService.finishFunction(finished);
  }

  public functionCase14(structure, param, finished) {
    this.sidenavService.close(0);
    this.functionsService.finishFunction(finished);
  }

  public functionCase15(structure, param, finished) {
    this.functionsService.revertValues(structure);
  }

  public functionCase17(structure, param, finished) {
    this.functionsService.deleteFunctionalArea(structure);
    this.functionsService.finishFunction(finished);
  }

  public functionCase18(structure, param, finished) {
    this.routingService.shouldOpenChat = true;
    this.functionsService.finishFunction(finished);
  }

  public functionCase19(structure, param, finished) {
    this.routingService.shouldOpenHelp = true;
    this.functionsService.finishFunction(finished);
  }

  public functionCase20(structure, param, finished) {
    let paramsExternalWindowOld = this.functionsService.cloneVariable(this.routingService.paramsExternalWindow);
    if (structure["customParams"] === undefined) {
      this.routingService.paramsExternalWindow = this.functionsService.getInternParam(structure, param);
      this.functionsService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
      if (this.routingService.paramsExternalWindow != paramsExternalWindowOld) this.routingService.shouldOpenExternalWindow = true;
      this.functionsService.finishFunction(finished);
    } else {
      this.routingService.paramsExternalWindow = structure["customParams"];
      this.functionsService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
      if (this.routingService.paramsExternalWindow != paramsExternalWindowOld) this.routingService.shouldOpenExternalWindow = true;
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase22(structure, param, finished) {
    this.routingService.openButtonAutoPlantilla.next();
    this.functionsService.finishFunction(finished);
  }

  public functionCase23(structure, param, finished) {
    this.functionsService.openAccountMode();
    this.functionsService.finishFunction(finished);
  }

  public functionCase24(structure, param, finished) {
    this.genericClientService.openColorPickerDialog(param);
    this.functionsService.finishFunction(finished);
  }

  public functionCase25(structure, param, finished) {
    this.functionsService.duplicateFunctionalAreaDB(structure, param);
  }

  public functionCase26(structure, param, finished) {
    this.genericClientService.openExternalUrl(structure);
  }

  public functionCase27(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.reloadQueries(structure, param, finished);
    });
  }

  public functionCase33(structure, param, finished) {
    this.functionsService.deleteDataDialog(structure, 0, finished);
  }

  public functionCase34(structure, param, finished) {
    this.functionsService.openBottomSheet(structure);
    this.functionsService.finishFunction(finished);
  }

  public functionCase35(structure, param, finished) {
    if (structure["id_functional_parent_initial_dsb"] == 0) {
      this.functionsService.getInternParam(structure, param);
      if(structure["massive_action"]) {
        this.functionsService.getInternParam(structure, null, 1);
      }
    }
    this.functionsService.openDialog(structure, 3);
  }

  public functionCase36(structure, param, finished) {
    this.functionsService.openFile(structure, param, false);
    this.functionsService.finishFunction(finished);
  }

  public functionCase37(structure, param, finished) {
    this.functionsService.openFile(structure, param, true);
    this.functionsService.finishFunction(finished);
  }

  public functionCase38(structure, param, finished) {
    this.functionsService.closeBottomSheet(structure);
    this.functionsService.finishFunction(finished);
  }

  public functionCase39(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, false, true, 2);
  }

  public functionCase40(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, false, true, 3);
  }

  public functionCase41(structure, param, finished) {
    this.functionsService.deleteDataDialog(structure, 1, finished);
  }

  public functionCase42(structure, param, finished) {
    this.functionsService.deleteDataDialog(structure, 2, finished);
  }

  public functionCase43(structure, param, finished) {
    this.functionsService.deleteDataDialog(structure, 3, finished);
  }

  public functionCase44(structure, param, finished) {
    this.functionsService.openDialog(structure, 4, this.functionsService.getInternParamDialog(structure, param));
  }

  public functionCase45(structure, param, finished) {
    this.functionsService.openDialog(structure, 5);
  }

  public functionCase46(structure, param, finished) {
    this.functionsService.openDialog(structure, 6, this.functionsService.getInternParamDialog(structure, param));
  }

  public functionCase47(structure, param, finished) {
    let from = 0;
    if (structure["id_functional_parent_initial"] == this.headerId || structure["id_functional_parent_initial"] == this.headerNoLoguedId) from = 1;
    if (structure["id_functional_parent_initial"] == this.footerId || structure["id_functional_parent_initial"] == this.footerNoLoguedId) from = 2;

    if (structure["id_functional_type"] != 3) {
      let struct = this.functionsService.findElementWithId(finished, false, false, true);
      if (!struct) return;
      struct["expandedElement"] = struct["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
    } else {
      structure["expandedElement"] = structure["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
    }

    this.functionsService.loadExpansion(param, from);
  }

  public functionCase48(structure, param, finished) {
    this.functionsService.fillExpansion(param, { 0: structure });
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = structure["description"];
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.functionsService.openSnackBar(structure["text"], 30000, ["green-snackbar"]);
  }

  public functionCase49(structure, param, finished) {
    let productVenta = this.functionsService.findProductFromParams(structure, param);
    this.functionsService.productEgoStart(1, productVenta); // PENDENT de fer funció a generic-client?? i posar codi a la bd
    this.functionsService.finishFunction(finished);
  }

  public functionCase50(structure, param, finished) {
    let productTutorial = this.functionsService.findProductFromParams(structure, param);
    this.functionsService.productEgoStart(2, productTutorial); // PENDENT de fer funció a generic-client?? i posar codi a la bd
    this.functionsService.finishFunction(finished);
  }

  public functionCase51(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false, null, 0);
  }

  public functionCase52(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false, null, 1);
  }

  public functionCase53(structure, param, finished) {
    this.functionsService.deleteDataDialog(structure, 4, finished);
  }

  public functionCase54(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 2, false, false);
  }

  public functionCase55(structure, param, finished) {
    let columnId = structure["internal_routing"];
    let textToCopy = "";
    for (let t in param) {
      if (t.includes(columnId)) {
        textToCopy = param[t];
      }
    }
    this.functionsService.clipboard.copy(textToCopy);
    this.functionsService.openSnackBar("Copiado en el portapapeles", 3000, ["green-snackbar"]);
  }

  public functionCase56(structure, param, finished, refresh = 0, type = null, publicar = null) {
    const v = this.functionsService.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        switch (data["errorCode"]) {
          case 0:
            let idPortal = null;
            let dataAcc = this.functionsService.getDataAccount();
            for (let t in this.functionsService.tmpArray) if (this.functionsService.tmpArray[t]["database"] == 1 && this.functionsService.tmpArray[t]["table"] == "portales" && this.functionsService.tmpArray[t]["field"] == "id") idPortal = this.functionsService.tmpArray[t]["value"];
            if (idPortal !== null) {
              this.endpointClientService.updateViviendaConfigPortalesMassive(idPortal, dataAcc).subscribe((data2) => {
                if (data2["response"] == 1) {
                  if (data2["errorCode"] == 0) {
                    this.functionsService.openSnackBar("Los datos se han guardado correctamente y la publicación en el portal se ha actualizado.\n Revisa y gestiona desde esta pantalla la publicación del portal.", 12000, ["green-snackbar"]);
                    this.functionsService.portalConfigured = idPortal;
                    this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
                    this.functionsService.dialog.closeAll();
                    this.functionsService.finishFunction(finished);
                    this.functionsService.goRouting("gestionar-inmuebles-en-portales", false);
                  } else {
                    if (data2["errorMessage"] !== null && data2["errorMessage"] !== "") {
                      this.functionsService.openSnackBar(
                        "Los datos se han guardado correctamente pero ha habido el siguiente error al actualizar alguno de los inmuebles: " +
                        data2["errorMessage"] +
                        ".\n Soluciona el error y actualízalos manualmente. Si persiste el error, ponte en contacto con nosotros en el chat de soporte.",
                        20000,
                        ["red-snackbar"]
                      );
                      this.functionsService.portalConfigured = idPortal;
                      this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
                      this.functionsService.dialog.closeAll();
                      this.functionsService.finishFunction(finished);
                      this.functionsService.goRouting("gestionar-inmuebles-en-portales", false);
                    }
                  }
                } else {
                  if (data2["errorCode"] == 0) {
                    this.functionsService.openSnackBar(
                      "Los datos se han guardado correctamente y se han despublicado los inmuebles del portal.",
                      6000,
                      ["green-snackbar"]
                    );
                    this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
                    this.functionsService.dialog.closeAll();
                    this.functionsService.finishFunction(finished);
                  }
                }
              });
            }
            break;
          case 1:
            this.functionsService.finishFunction(finished);
            break;
        }
      });
    }
  }

  public functionCase57(structure, param, finished) {
    this.functionsService.productEgoStart(1, structure["internal_routing"]); // PENDENT de fer funció a generic-client?? i posar codi a la bd
    this.functionsService.finishFunction(finished);
  }

  public functionCase58(structure, param, finished) {
    this.functionsService.productEgoStart(2, structure["internal_routing"]); // PENDENT de fer funció a generic-client?? i posar codi a la bd
    this.functionsService.finishFunction(finished);
  }

  public functionCase59(structure, param, finished) {
    this.functionsService.downloadPDF(1);
  }

  public functionCase60(structure, param, finished) {
    this.functionsService.downloadPDF(0);
  }

  public functionCase62(structure, param, finished) {
    this.genericClientService.sendWhatsAppClienteContactar(structure, param, finished);
  }

  public functionCase63(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let url = this.genericClientService.buildURLFichaInmueble(structure, -1);
      window.open(url, "_blank");
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase64(structure, param, finished) {
    this.genericClientService.getTextInmueble(structure, param, finished, 1); // whatsapp empresa
  }

  public functionCase65(structure, param, finished) {
    this.genericClientService.getTextInmueble(structure, param, finished, 2); // contenido email empresa
  }

  public functionCase66(structure, param, finished) {
    this.genericClientService.getTextInmueble(structure, param, finished, 3); //  asunto empresa
  }

  public functionCase67(structure, param, finished) {
    this.genericClientService.updateImagesProperty(finished);
  }

  public functionCase68(structure, param, finished) {
    this.functionsService.vincularGoogleMail(structure, param, finished);
  }

  public functionCase70(structure, param, finished) {
    this.functionsService.updateConditionsCustom(finished);
  }

  public functionCase71(structure, param, finished) {
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let idUser = this.authService.userId;
    let nombreEmpresa = this.authService.companyGenericName;

    //Casos generals generic
    this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();

    let nombreReport =
      param.param_intern[
      "statichtml_routing_functional_area_language-internal_routing"
      ];
    let idReport =
      param.param_intern["statichtml_functional_area-id_functional_area"];
    let url =
      "../" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "-" +
      idUser +
      "&&" +
      idReport +
      "&&0";
    window.open(url);
    this.functionsService.finishFunction(finished);
  }

  public functionCase72(structure, param, finished) {
    this.templateService.generateHTMLResult();
    this.functionsService.updatePlantillaElementsCustom(finished);
  }

  public functionCase82(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let idInmueble = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idInmueble = values[i]["value"];
          break;
        }
      }

      this.endpointClientService.duplicarInmueble(idInmueble).subscribe(data => {
        this.functionsService.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["green-snackbar"],
        });
        console.log(structure, structure["internal_routing"] !== undefined, structure["internal_routing"] !== "", structure["internal_routing"] != null);
        if (structure["internal_routing"] !== undefined && structure["internal_routing"] !== "" && structure["internal_routing"] != null) {
          this.functionsService.addWindowParam(data["response"], 1, "vivienda", "id", structure['id_functional_parent_initial'], true, "output");
          this.functionsService.route = structure["internal_routing"];
          this.functionsService.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.functionsService.goRouting(this.functionsService.route, false);
        } else {
          this.functionsService.addWindowParam(data["response"], 1, "vivienda", "id", structure['id_functional_parent_initial'], true, "input");
          this.functionsService.refreshStructure();
        }
      })
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase84(structure, param, finished) {
    this.genericClientService.sendWhatsAppClienteContactar(structure, param, finished);
  }

  public functionCase85(structure, param, finished) {
    this.genericClientService.getTextInmueble(structure, param, finished, 4); // whatsapp inmueble
  }

  public functionCase86(structure, param, finished) {
    this.genericClientService.getTextInmueble(structure, param, finished, 5); // contenido email inmueble
  }

  public functionCase87(structure, param, finished) {
    this.genericClientService.getTextInmueble(structure, param, finished, 6); //  asunto inmueble
  }

  public functionCase88(structure, param, finished) {
    this.functionsService.saveTextInmueble(structure, param, finished, 1); //  mensaje whatsapp inmueble
  }

  public functionCase89(structure, param, finished) {
    this.functionsService.saveTextInmueble(structure, param, finished, 2); //  mensaje email inmueble
  }

  public functionCase90(structure, param, finished) {
    this.functionsService.saveTextInmueble(structure, param, finished, 3); //  asunto email inmueble
  }

  public functionCase91(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, false, false, null, null, true);
  }

  public functionCase93(structure, param, finished) {
    this.functionsService.loadClientsFromFile(structure, param, finished);
  }

  public functionCase94(structure, param, finished) {
    this.functionsService.duplicatePlantillaEmail(param, finished, structure);
  }

  public functionCase95(structure, param, finished) {
    this.functionsService.duplicatePlantillaEmail(param, finished, structure, 1);
  }

  public functionCase96(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let url = this.genericClientService.buildURLFichaPropietario();
      window.open(url, "_blank");
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase97(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let url = this.genericClientService.buildURLVerForm(structure, param);
      window.open(url, "_blank");
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase98(structure, param, finished) {
    let paramIntern = [];
    let intern_data = this.functionsService.getInternParam(structure, paramIntern);

    console.log("intern_data", this.functionsService.cloneVariable(intern_data));
    let url = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_multimedia" && intern_data[i]["bd_field"] == "url") {
        url = intern_data[i]["value"];
        break;
      }
    }
    let fullUrl = "https://movin.cloud/empresas/" + this.authService.empresaId + "/media/" + url;
    this.templateService.currentElement["url"] = fullUrl;
    this.templateService.modifyElement("url");
    this.functionsService.dialog.closeAll();
    this.functionsService.finishFunction(finished);
  }

  public functionCase99(structure, param, finished) {
    let cleanElementsJourney = [];
    let valid = true;
    for (let i in this.journeyService.elementsJourney) {
      if (this.journeyService.elementsJourney[i]['id_type'] == 1) {
        this.journeyService.elementsJourney[i].formCreator = {};
        cleanElementsJourney.push(this.journeyService.elementsJourney[i]);
      }
      if (this.journeyService.elementsJourney[i]['id_type'] == 3) {
        valid = false;
        break;
      }
    }
    if (valid) {
      this.functionsService.updateJourneysCreator(cleanElementsJourney, finished);
    } else {
      this.functionsService.finishFunction(finished);
      this.functionsService.openSnackBar("No puedes dejar campos sin rellenar después de un IF/ELSE. En caso de que no quieras añadir nada, puedes añadir un elmento de tipo 'Finalizar Journey'.", 15000, ["red-snackbar"]);
    }
  }

  public functionCase100(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let idVivienda = null;
      let motivoCancelacion = "Demanda cancelada automáticamente de manera masiva a partir del inmueble";
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idVivienda = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario" && values[i]["field"] == "can_motivo") {
          motivoCancelacion = values[i]["value"];
        }
      }

      this.endpointClientService.archiveAllStatusDemandaFromViviendaCustom(idVivienda, motivoCancelacion).subscribe((data) => {
        this.functionsService.finishFunction(finished);
        if (data["errorCode"] == 0) {
          this.functionsService.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
          this.functionsService.refreshStructure(0);
        } else {
          this.functionsService.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
        }
      });
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase101(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    this.functionsService.refreshStructure(0);
  }

  public functionCase102(structure, param, finished) {
    this.functionsService.finishFunction(finished);
      this.functionsService.refreshStructure(1);
  }

  public functionCase103(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    this.functionsService.refreshStructure(2);
  }

  public functionCase104(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let cantidad = 0;
      let precio = 0;
      let importeTotal = 0;
      let tablesOpciones = ["rest_pedidos_productos_opciones_medidas", "rest_pedidos_productos_opciones_suplementos", "rest_pedidos_productos_opciones_opciones", "rest_pedidos_productos_opciones_preparacion"];
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos" && values[i]["field"] == "cantidad") {
          cantidad = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_productos" && values[i]["field"] == "precio") {
          precio = values[i]["value"];
        }
        for (let t of tablesOpciones) {
          if (values[i]["database"] == 1 && values[i]["table"] == t && values[i]["field"] == "id_opcion") {
            if (!values[i]["value"]) continue;
            if (!values[i]["value"][0]) values[i]["value"] = [values[i]["value"]];
            for (let j of values[i]["value"]) {
              let idFA = this.functionsService.findFAWithTableField(1, t, "id_opcion", structure["id_functional_parent_initial_dsb"]);
              for (let k of this.selectOptions[idFA['id_query']]) {
                if (j == k['value'] && k['price'] && k['price'] > 0) {
                  precio = +precio + +k['price'];
                  break;
                }
              }
            }
          }
        }
      }
  
      importeTotal = cantidad * precio;
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_productos", "importe", structure["id_functional_parent_initial_dsb"]);
      this.functionsService.assignValueFunctionalArea(idFA, importeTotal);
      // idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_productos", "importe_unitario", structure["id_functional_parent_initial_dsb"]);
      // this.assignValueFunctionalArea(idFA, precio);
    });
  }

  public functionCase105(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    let url = this.genericClientService.urlCheckOutReservaHotel(structure, param, true);
    if (url != null) {
      localStorage.clear();
      localStorage.setItem("lastUrl", JSON.stringify(url));
      localStorage.setItem("numPersonas", JSON.stringify(this.authService.numPersonas));
      localStorage.setItem("numAdultos", JSON.stringify(this.authService.numAdultos));
      localStorage.setItem("numNinos", JSON.stringify(this.authService.numNinos));
      localStorage.setItem("numBebes", JSON.stringify(this.authService.numBebes));
      localStorage.setItem("daysCounter", JSON.stringify(this.authService.daysCounter));
      localStorage.setItem("reservaInfo", JSON.stringify(this.authService.reservaInfo));

      window.open(url);

    }
  }

  public functionCase106(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));

    if (validReq) {
      let id_empresa = this.authService.getIdEmpresa();
      let id_producto = null;
      let nombre_fichero = null;

      let tablesOpciones = ["rest_pedidos_productos_opciones_medidas", "rest_pedidos_productos_opciones_suplementos", "rest_pedidos_productos_opciones_opciones", "rest_pedidos_productos_opciones_preparacion"];

      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos" && values[i]["field"] == "cantidad") {
          this.authService.productsCounter += +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos" && values[i]["field"] == "importe") {
          this.authService.productsPriceCounter += +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_productos" && values[i]["field"] == "id_producto") {
          id_producto = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_productos_imagenes" && values[i]["field"] == "nombre_fichero") {
          nombre_fichero = values[i]["value"];
        }
        if (values[i]["database"] == 1 && tablesOpciones.includes(values[i]["table"])) {
          values[i]["value_label"] = [];
          for (let t of tablesOpciones) {
            if (values[i]["database"] == 1 && values[i]["table"] == t && values[i]["field"] == "id_opcion" && values[i]["value"] != null) {
              // console.log("HELLOW ", t, " . ", values[i]["value"]);
              if (!values[i]["value"][0]) values[i]["value"] = [values[i]["value"]];
              for (let j of values[i]["value"]) {
                let idFA = this.functionsService.findFAWithTableField(1, t, "id_opcion", structure["id_functional_parent_initial_dsb"]);
                for (let k of this.selectOptions[idFA['id_query']]) {
                  if (j == k["value"]) values[i]["value_label"].push(k['text']);
                }
              }
            }
          }
        }
      }
      //      let url = "../empresas/restaurantes/"+ id_empresa + "/imagenes/productos/" +  id_producto + "/"+ nombre_fichero;
      let url = "https://movin.cloud/empresas/restaurantes/" + id_empresa + "/imagenes/productos/" + id_producto + "/" + nombre_fichero;
      values.push({ database: 1, table: "rest_productos_imagenes", field: "url", value: url });
      this.authService.arrayProductos.push(values);
      this.authService.productsPriceCounter = Math.round((this.authService.productsPriceCounter + Number.EPSILON) * 100) / 100;
      this.authService.productsPriceCounterLabel = this.functionsService.cloneVariable(this.authService.productsPriceCounter).toString().replace(".", ",");

      localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
      localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
      localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));
      localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
      this.functionsService.dialog.closeAll();
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase109(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      let structureClone = this.functionsService.cloneVariable(structure);
      structureClone['id_function'] = 2;
      this.functionsService.executeNewFunction({structure: structureClone, param, finished});
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase110(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe = 0;
      let descuento = 0;
      let tipo_descuento = 0;
      let importeTotal = 0
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importe = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
          descuento = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "id_descuento_unidad") {
          tipo_descuento = values[i]["value"];
        }
      }
  
      if (tipo_descuento == 1) {
        importeTotal = importe - descuento;
      } else if (tipo_descuento == 2) {
        importeTotal = importe - (importe * descuento / 100);
      } else {
        importeTotal = importe;
      }
      if (importeTotal < 0) importeTotal = 0;
      if (importeTotal >= 0) {
        importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotal);
      }
    });
  }

  public functionCase112(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let n_dieta = null;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "medicina_clientes_dietas_periodo" && intern_data[i]["bd_field"] == "id_cliente_dieta_periodo" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          n_dieta = intern_data[i]["value"];
          break;
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "medicina_clientes_dieta" && intern_data[i]["bd_field"] == "id_cliente_dieta" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          n_dieta = intern_data[i]["value"];
          break;
        }
      }
  
      //Casos generals generic
      this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "dieta" + "/sth/" + idEmpresa + "-0&&" + n_dieta + "&&0";
      window.open(url);
    });
  }

  public functionCase113(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param, false);
    let allData = {
      paramInterns: this.functionsService.getInternParam(structure, param),
      actualValueFields: JSON.parse(JSON.stringify(this.functionsService.tmpArray)),
      dataAccount: this.functionsService.getDataAccount(),
      faWithQueriesToRefresh: structure["internal_routing_id_functional_area"],
      staticHTML: this.staticHTML,
    };
    for (let i in allData.paramInterns) {
      if (allData.paramInterns[i]["id_db"] == 1 && allData.paramInterns[i]["bd_table"] == "act_depor_actividad_precio" && allData.paramInterns[i]["bd_field"] == "precio") {
        if (allData.paramInterns[i]["value"] == null || allData.paramInterns[i]["value"] == "" || allData.paramInterns[i]["value"] == -1) {
          this.authService.importDeportActivity = allData.paramInterns[i]["value"];
        } else {
          this.authService.importDeportActivity = allData.paramInterns[i]["value"];
        }
      }
    }
    this.functionsService.reloadQueries(structure, param, finished);
  }

  public functionCase114(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe = 0;
      let descuento = 0;
      let tipo_descuento = 0;
      let importeTotal = 0
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importe = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
          descuento = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "id_descuento_unidad") {
          tipo_descuento = values[i]["value"];
        }
      }
  
      if (tipo_descuento == 1) {
        importeTotal = importe - descuento;
      } else if (tipo_descuento == 2) {
        importeTotal = importe - (importe * descuento / 100);
      } else {
        importeTotal = importe;
      }
      if (importeTotal < 0) importeTotal = 0;
      if (importeTotal >= 0) {
        importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "act_ludic_reservas_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotal);
      }
    });
  }

  public functionCase115(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      setTimeout(() => {
        let idLanguage = this.authService.labelLanguage;
        let idEmpresa = this.authService.getIdEmpresa();
        let nombreEmpresa = this.authService.companyGenericName;
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let periodo = 0;
        let n_dieta = null;
        for (let i in values) {
          if (periodo == 0 && values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dietas_periodo" && values[i]["field"] == "id_cliente_dieta_periodo" && values[i]["value"] && values[i]["value"] > 0) {
            periodo = values[i]["value"];
          }
          if (n_dieta == null && values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dietas_periodo" && values[i]["field"] == "id_dieta" && values[i]["value"] && values[i]["value"] > 0) {
            n_dieta = values[i]["value"];
          }
          if (n_dieta == null && values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dieta" && values[i]["field"] == "id_cliente_dieta" && values[i]["value"] && values[i]["value"] > 0) {
            n_dieta = values[i]["value"];
          }
        }
    
        if (periodo > 0 && n_dieta != null) {
    
          //Casos generals generic
          this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
    
          let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "dieta" + "/sth/" + idEmpresa + "-0&&" + n_dieta + "&&" + periodo;
          window.location.href = url;
        }
      });
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase116(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      setTimeout(() => {
        let idLanguage = this.authService.labelLanguage;
        let idEmpresa = this.authService.getIdEmpresa();
        let nombreEmpresa = this.authService.companyGenericName;
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);;
        let n_dieta = null;
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dietas_periodo" && values[i]["field"] == "id_dieta" && values[i]["value"] && values[i]["value"] > 0) {
            n_dieta = values[i]["value"];
            break;
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dieta" && values[i]["field"] == "id_cliente_dieta" && values[i]["value"] && values[i]["value"] > 0) {
            n_dieta = values[i]["value"];
            break;
          }
        }
    
        if (n_dieta != null) {
    
          //Casos generals generic
          this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
    
          let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "dieta" + "/sth/" + idEmpresa + "-0&&" + n_dieta + "&&0";
          window.location.href = url;
        }
      });
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase117(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      setTimeout(() => {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let importe = 0;
        let iva = 0;
        let importeTotal = 0;
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "encargo" && values[i]["field"] == "importe_sin_iva" && values[i]["value"] && values[i]["value"] > 0) {
            importe = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "encargo" && values[i]["field"] == "porcentaje_iva" && values[i]["value"] && values[i]["value"] > 0) {
            iva = values[i]["value"];
          }
        }
    
        if (importe < 0) importe = 0;
        if (iva < 0) iva = 0;
        if (importe >= 0 && iva >= 0) {
          importe = +importe;
          iva = +iva;
          importeTotal = +(importe * iva / 100) + importe;
          if (!isNaN(importeTotal) && isFinite(importeTotal)) {
            importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
            let importeTotalDecimal = importeTotal.toString().replace('.', ',');
            let idFA = this.functionsService.findFAWithTableField(1, "encargo", "importe_con_iva", structure["id_functional_parent_initial_dsb"]);
            this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
          } else {
            console.log("El importe total no es un número válido.");
          }
        }
      });
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase118(structure, param, finished) {
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let tableInsert = "rest_pedidos_cierres_caja";
      let importeTotal = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && (values[i]["table"] == "rest_pedidos_cierres_caja_payment_method" || values[i]["table"] == "rest_pedidos_cierres_caja_payment_method_snapshot") && values[i]["field"] == "importe_manual" && values[i]["value"] && values[i]["value"] > 0) {
          importeTotal = importeTotal + +values[i]["value"];
          if (values[i]["table"] == "rest_pedidos_cierres_caja_payment_method_snapshot") tableInsert = "rest_pedidos_cierres_caja_snapshot";
        }
      }
  
      if (!isNaN(importeTotal) && isFinite(importeTotal)) {
        importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importeTotal.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, tableInsert, "importe_manual", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase119(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let idReserva = null;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "gest_alquiler_reservas" && intern_data[i]["bd_field"] == "id_reserva" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          idReserva = intern_data[i]["value"];
          break;
        }
      }
  
      //Casos generals generic
      this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "incidencia-reserva" + "/sth/" + idEmpresa + "-0&&" + idReserva + "&&0";
      window.open(url);
    });
  }

  public functionCase120(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    this.functionsService.updatePaymentMethod(structure, 1);
  }

  public functionCase121(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    this.functionsService.updatePaymentMethod(structure, 0);
  }

  public functionCase122(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    if (structure['internal_routing_id_functional_area'] && structure['internal_routing_id_functional_area'] != "" && structure['internal_routing_id_functional_area'] != 0) this.checkoutUrl = structure['internal_routing_id_functional_area'];
    let validReq = this.functionsService.formsArray(structure, true, false);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let id_payment = null;
      let id_empresa = null;
      let id_payment_methods = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment") {
          id_payment = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_empresa") {
          id_empresa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment_methods") {
          id_payment_methods = values[i]["value"];
        }
      }


      //Casos generals generic
      this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();

      window.open("../es/empresa/" + this.checkoutUrl + "/sth/" + String(id_empresa) + "-0&&" + String(id_payment), "_self");
    }
  }

  public functionCase123(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, false, false, 18);
    this.functionsService.finishFunction(finished);
  }

  public functionCase124(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let importeDiferencia = 0;
        let importePagado = 0;
        let importeAPagar = 0;
        let importePropina = 0;
        let indexNgForEfectivo = null;
        let hasEfectivo = false;
    
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets_payment_methods" && values[i]["field"] == "id_payment_method" && values[i]["value"] && values[i]["value"] > 0 && values[i]["value"] == 5) {
            let indexNgForEfectivoArray = values[i]["functionalArea"].split(".");
            indexNgForEfectivo = indexNgForEfectivoArray[indexNgForEfectivoArray.length - 1];
            break;
          }
        }
        console.log("values[i]]", values);
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe_final" && values[i]["value"]) {
            console.log("values[i]]", values[i]["value"]);
            importeAPagar = +values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
            importePagado = importePagado + +values[i]["value"];
            let indexNgForEfectivoArrayImporte = values[i]["functionalArea"].split(".");
            let indexNgForEfectivoImporte = indexNgForEfectivoArrayImporte[indexNgForEfectivoArrayImporte.length - 1];
            if (indexNgForEfectivo != null && indexNgForEfectivoImporte == indexNgForEfectivo) {
              hasEfectivo = true;
            }
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "propina" && values[i]["value"] && values[i]["value"] > 0) {
            importePagado = importePagado - +values[i]["value"];
            importePropina = +values[i]["value"];
          }
        }
    
    
        importeDiferencia = importeAPagar - importePagado;
        if (!isNaN(importeDiferencia) && isFinite(importeDiferencia)) {
          importeDiferencia = Math.round((+importeDiferencia + Number.EPSILON) * 100) / 100;
          let idFA = this.functionsService.findFAWithTableField(1, "comodin_box_propina", "comodin_box_propina", structure["id_functional_parent_initial_dsb"], -1, true, true);
          if (idFA !== null) {
            if (hasEfectivo) {
              idFA['id_functional_status_general'] = 2;
              if (importeDiferencia < 0 && !this.snackbarOpened) this.functionsService.openSnackBar('Hay dinero sobrante y no puedes marcar "Cobrado", pero no puedes añadir propina si tienes indicado "Efectivo". Debes retirar el efectivo primero.', 50000, ["red-snackbar"]);
              importePropina = 0;
              let idFA2 = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets", "propina", structure["id_functional_parent_initial_dsb"], -1, true);
              this.functionsService.assignValueFunctionalArea(idFA2, 0);
            } else {
              idFA['id_functional_status_general'] = 1
              this.functionsService.snackBar.dismiss();
            }
          }
          let importeTotalDecimal = importeDiferencia.toString().replace('.', ',');
          let idFA2 = this.functionsService.findFAWithTableField(1, "comodin_diferencia", "comodin_diferencia_importe", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA2, importeTotalDecimal);
          if (importePropina > 0 && importeDiferencia != 0 && !hasEfectivo) this.functionCase190(structure, param, finished, 0);
        } else {
          console.log("El importe total no es un número válido.");
        }
    });
  }

  public functionCase125(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      //if(!structure['setFieldsIDClienteEmpresa']) {
        structure['setFieldsIDClienteEmpresa'] = false;
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let id = 0;
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "clientes" && values[i]["field"] == "id_cliente_empresa" && values[i]["value"] && values[i]["value"] > 0) {
            id = values[i]["value"];
            //structure['setFieldsIDClienteEmpresa'] = true;
            break;
          }
        }
    
        let idFA = this.functionsService.findFAWithTableField(1, "clientes_empresa", "id_cliente_empresa", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, id);
      //}
    });
  }

  public functionCase126(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importePendiente = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
          importePendiente = +values[i]["value"];
        }
      }
  
      if (!isNaN(importePendiente) && isFinite(importePendiente)) {
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let val = this.functionsService.cloneVariable(idFA["tmp_value"]).replace(',', '.')
        if (val) importePendiente = +importePendiente + +val;
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importePendiente.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      }
    });
  }

  public functionCase127(structure, param, finished) {
    this.functionsService.makePayment(structure, param, finished);
  }

  public functionCase128(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let precio = 0;
      let num_unidades = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_reservas" && values[i]["field"] == "precio_actividad" && values[i]["value"]) {
          precio = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_reservas" && values[i]["field"] == "num_unidades" && values[i]["value"]) {
          num_unidades = +values[i]["value"];
        }
      }
  
      let importeTotal = num_unidades * precio;
      if (!isNaN(importeTotal) && isFinite(importeTotal)) {
        importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "act_depor_reservas", "importe_total", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let importeTotalDecimal = importeTotal.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
        this.functionsService.assignPaymentImport(importeTotal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase129(structure, param, finished) {
    setTimeout(() => {
      let idCliente = null;
      if (structure['tmp_value'] && structure['tmp_value'] > 0) idCliente = structure['tmp_value'];
      let idFA = this.functionsService.findFAWithTableField(1, "clientes", "id", structure["id_functional_parent_initial_dsb"]);
      this.functionsService.assignValueFunctionalArea(idFA, idCliente);
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase130(structure, param, finished) {
    setTimeout(() => {
      let fecha_fin = null;
      if (structure['tmp_value']) {
        let tmpValue = this.functionsService.cloneVariable(structure['tmp_value'])
        let originalDate: Date = new Date(tmpValue);
        fecha_fin = new Date(originalDate.getTime() + (60 * 60 * 1000));
        let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario", "fecha_fin", structure["id_functional_parent_initial_dsb"]);
        let dateOldVal = this.functionsService.cloneVariable(idFA['tmp_value']);
        let dateold: Date = new Date(dateOldVal);
        if (!idFA['tmp_value'] || idFA['tmp_value'] == null || dateold < originalDate || idFA['myVal'] == dateOldVal) {
          fecha_fin = this.functionsService.formatDate(fecha_fin);
          this.functionsService.assignValueFunctionalArea(idFA, fecha_fin);
          idFA['myVal'] = this.functionsService.cloneVariable(fecha_fin);
        }
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase131(structure, param, finished) {
    this.endpointClientService.insertPedidoStaticRestaurant(this.authService.arrayProductos, this.authService.productsPriceCounter, this.authService.empresaId).subscribe((data) => {
      let id_ticket = data['response']['id_ticket'];
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;

      this.authService.arrayProductos = [];
      this.authService.productsCounter = 0;
      this.authService.productsPriceCounter = 0;
      this.authService.productsPriceCounterLabel = "0.00";

      localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
      localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
      localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
      localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));

      this.functionsService.route = structure["internal_routing"];
      structure["internal_routing"] = "../" + idLanguage + "/" + nombreEmpresa + "/pedido-cliente-restaurante" + "/sth/" + idEmpresa + "-0&&" + id_ticket;
      if (data['response']['id_mesa'] == -1) {
        this.genericClientService.openExternalUrl(structure);

      }
      else {
        this.functionsService.openSnackBar(
          "Tu pedido se ha realizado correctamente!",
          7000,
          ["green-snackbar"]
        );
      }
      this.functionsService.dialogRef.close(structure, []);
      this.functionsService.finishFunction(true);
    });
  }

  public functionCase132(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.removeTableRelationWhenValue(structure, param); 
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase133(structure, param, finished) {
    setTimeout(() => {
      let idFAsearchInicio = 0;
      if (structure['id_functional_parent_initial_dsb'] == 33949) { //dialog add 33949
        idFAsearchInicio = 33973;
      } else if (structure['id_functional_parent_initial_dsb'] == 34400) { //dialog edit 34400
        idFAsearchInicio = 34425;
      }
  
      if (idFAsearchInicio != 0) {
        let fecha_inicio_idFA = this.functionsService.findFunctionalArea(this.authService.headerStructure, idFAsearchInicio);
        if (fecha_inicio_idFA && fecha_inicio_idFA['tmp_value']) {
          let fecha_inicio = this.functionsService.cloneVariable(fecha_inicio_idFA['tmp_value']);
          fecha_inicio = fecha_inicio.split(" ")[0];
          let idFAInicio = this.functionsService.findFAWithTableField(1, "actividades_calendario", "fecha_inicio", structure["id_functional_parent_initial_dsb"]);
          if (idFAInicio['id_functional_area'] != idFAsearchInicio) this.functionsService.assignValueFunctionalArea(idFAInicio, fecha_inicio);
        }
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase135(structure, param, finished) {
    setTimeout(() => {
      if (structure['tmp_value']) {
        let originalDate = this.functionsService.formatDate(this.functionsService.cloneVariable(structure['tmp_value']));
        let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario", "fecha_fin", structure["id_functional_parent_initial_dsb"], -1, false);
        originalDate = originalDate.split(" ")[0];
        if (!idFA['tmp_value'] || idFA['tmp_value'] == null || idFA['tmp_value'] < originalDate || idFA['myVal'] == idFA['tmp_value']) {
          this.functionsService.assignValueFunctionalArea(idFA, originalDate);
          idFA['myVal'] = this.functionsService.cloneVariable(originalDate);
        }
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase137(structure, param, finished) {
    setTimeout(() => {
      if (!structure['estadoInit'] || structure['estadoInit'] == undefined) {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario" && values[i]["field"] == "estado" && values[i]["value"] && values[i]["value"] > 0) {
            if (values[i]["value"] != 1 && values[i]["value"] != 4) {
              //this.functionsService.findElementWithIdAndSetStatus(34477, 2); //button guardar
              //this.functionsService.findElementWithIdAndSetStatus(53810, 2); //button guardar
              this.functionsService.findElementWithIdAndSetStatus(34233, 2); //button firmar
              structure['id_functional_status_general'] = 3;
              this.functionsService.openSnackBar("¡Esta actividad no se debe modificar porque no está ni Abierta ni En proceso!", 7000, ["blue-snackbar",]);
            }
            break;
          }
        }
        structure['estadoInit'] = true;
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase138(structure, param, finished) {
    let x = { id_function: 35, internal_routing_id_functional_area: 33949, refresh_params: 1 };
    const now = new Date();
    this.functionsService.addWindowParam(this.functionsService.formatDate(now, true), 1, "actividades_calendario", "fecha_inicio", 89); // Hay que poner en el header porque el Dialog esta en el Header
    x['refresh_params'] = 2;
    this.functionsService.finishFunction(finished);
    this.functionsService.executeNewFunction({structure: x, param: null, finished});
  }

  public functionCase139(structure, param, finished) {
    let type = null, publicar = null
    setTimeout(() => {
      if (this.functionsService.checkPreviewModeFunction(finished)) {
        return;
      }
      let structure_pre = this.functionsService.cloneVariable(structure);
      const v = this.functionsService.insertFormData(structure, param, finished, type, publicar);
      if (v != null) {
        v.subscribe((data) => {
          if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
            let inserts = data["response"];
            for (let i in inserts) {
              if (inserts[i]['bd_table'] == "rest_pedidos" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_pedido" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
                this.functionsService.addWindowParam(inserts[i]['responseId'], 1, "rest_pedidos", "id_pedido", structure['id_functional_parent_initial'], true, 'input');
              }
            }
            this.functionsService.openSnackBar("Guardado con éxito", 6000, ["green-snackbar",]);
            this.functionsService.refreshStructure();
          }
        });
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase141(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let importeTotal = this.functionsService.cloneVariable(structure['tmp_value']).replaceAll(',', '.');
      if (importeTotal && importeTotal > 0) {
        this.functionsService.assignPaymentImport(importeTotal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase142(structure, param, finished) {
    let type = null, publicar = null;
    setTimeout(() => {
      if (this.functionsService.checkPreviewModeFunction(finished)) {
        return;
      }
      let structure_pre = this.functionsService.cloneVariable(structure);
      const v = this.functionsService.insertFormData(structure, param, finished, type, publicar);
      if (v != null) {
        v.subscribe((data) => {
          if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
            let inserts = data["response"];
            for (let i in inserts) {
              if (inserts[i]['bd_table'] == "rest_pedidos_tickets" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_ticket" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
                this.functionsService.addWindowParam(inserts[i]['responseId'], 1, "rest_pedidos_tickets", "id_ticket", structure['id_functional_parent_initial']);
                structure['refresh_params'] = 2;
                this.functionsService.openDialog(structure, 2);
                this.functionsService.openSnackBar("Ticket generado correctamente", 6000, ["green-snackbar"]);
                break;
              }
            }
          }
        });
      }
    });
  }

  public functionCase143(structure, param, finished) {
    this.functionsService.saveInsertedValuesAndRouting(1, structure, param, finished);
    this.functionsService.finishFunction(finished);
  }

  public functionCase144(structure, param, finished) {
    let id_inmueble_idFA = this.functionsService.findFAWithTableField(1, "actividades_viviendas", "id_vivienda", structure["id_functional_parent_initial_dsb"]);
    if (id_inmueble_idFA && id_inmueble_idFA['tmp_value']) {
      let id_inmueble = this.functionsService.cloneVariable(id_inmueble_idFA['tmp_value'][0]);
      this.endpointClientService.getPropertyLocation(id_inmueble).subscribe((data) => {
        if (data['response'] && data['response'][0] && data['response'][0]['direccion']) {
          let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario", "localizacion", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, data['response'][0]['direccion']);
        }
        else {
          this.functionsService.openSnackBar("Este inmueble no tiene dirección", 5000, [
            "red-snackbar",
          ]);
        }
      });
    }
  }

  public functionCase145(structure, param, finished) {
    let id_cliente_idFA = this.functionsService.findFAWithTableField(1, "actividades_clientes", "id_cliente", structure["id_functional_parent_initial_dsb"]);
    if (id_cliente_idFA && id_cliente_idFA['tmp_value']) {
      let id_cliente = this.functionsService.cloneVariable(id_cliente_idFA['tmp_value'][0]);
      this.endpointClientService.getClientLocation(id_cliente).subscribe((data) => {
        if (data['response'] && data['response'][0] && data['response'][0]['direccion']) {
          let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario", "localizacion", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, data['response'][0]['direccion']);
        }
        else {
          this.functionsService.openSnackBar("Este cliente no tiene dirección", 5000, [
            "red-snackbar",
          ]);
        }
      });
    }
  }

  public functionCase146(structure, param, finished) {
    this.functionsService.saveInsertedValuesAndRouting(2, structure, param, finished);
    this.functionsService.finishFunction(finished);
  }

  public functionCase147(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idTicket = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_tickets" && intern_data[i]["bd_field"] == "id_ticket" && intern_data[i]["value"]) {
        idTicket = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idTicket > 0) {
      this.printTicketService.printTicketRestaurant(idTicket);
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún ticket a imprimir", 4000, ["red-snackbar"]);
    this.functionsService.finishFunction(finished);
  }

  public functionCase148(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let pedidos = null;
      for (let i in values) {
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "rest_pedidos" &&
          values[i]["field"] == "comodin_pedidos"
        ) {
          pedidos = values[i]["value"];
          break;
        }
      }

      if (pedidos != null && pedidos.length > 1) {
        this.endpointClientService.mergePedidosRestaurant(pedidos).subscribe((data) => { });
        this.functionsService.openSnackBar("Pedidos juntados correctamente", 3500, ["green-snackbar",]);
        this.functionsService.refreshStructure(0);
      }
      else {
        this.functionsService.openSnackBar("Elige com a mínimo dos pedidos", 3500, ["red-snackbar",]);
      }
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase149(structure, param, finished) {
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let pedidoOrigen = null;
        let juntarPedido = null;
        let mesaDestino = null;
        let pedidoDestino = null;
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos" && values[i]["field"] == "id_pedido") {
            pedidoOrigen = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "rest_mesas" && values[i]["field"] == "id_mesa") {
            mesaDestino = values[i]["value"];
          }
          else if (values[i]["database"] == 1 && values[i]["table"] == "comodin_mesas" && values[i]["field"] == "comodin_juntar_pedidos") {
            juntarPedido = values[i]["value"];
          }
          else if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos" && values[i]["field"] == "comodin_id_pedido") {
            pedidoDestino = values[i]["value"];
          }
        }
        let res = null;
        this.endpointClientService.movePedidoRestaurant(pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino).subscribe((data) => {
          res = data["response"];
          if (res == 1) {
            this.functionsService.refreshStructure(0);
          }
          else {
            this.functionsService.goPrev(true, null);
          }
  
        });
        this.functionsService.refreshStructure(0);
      }
      this.functionsService.finishFunction(finished);
    });
  }

  public functionCase150(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let idPedidoProducto = null;
      let cantidadTotal = null;
      let importeTotal = null;
      let cantidadCobro = null;
      let cantidadCobro2 = null;
      let dividir = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos") {
          if (values[i]["field"] == "id_pedido_producto") {
            idPedidoProducto = values[i]["value"];
          }
          else if (values[i]["field"] == "cantidad") {
            cantidadTotal = values[i]["value"];
          }
          else if (values[i]["field"] == "importe") {
            importeTotal = values[i]["value"];
          }
        }
        else if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos_productos") {
          if (values[i]["field"] == "comodin_cantidad") {
            cantidadCobro = values[i]["value"];
          }
          else if (values[i]["field"] == "comodin_cantidad2") {
            cantidadCobro2 = values[i]["value"];
          }
          else if (values[i]["field"] == "comodin_dividir_producto") {
            dividir = values[i]["value"];
          }
        }
      }
      if (dividir == 1) cantidadCobro = cantidadCobro2;
      this.endpointClientService.addToCheckoutRestaurant(idPedidoProducto, cantidadTotal, importeTotal, cantidadCobro).subscribe((data) => {
        this.functionsService.dialog.closeAll();
        this.functionsService.updateResults(this.functionsService.getInternParam(structure, param), structure['id_functional_parent_initial']);
      });
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase151(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idVivienda = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "id" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idVivienda = intern_data[i]["value"];
        break;
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "actividades_viviendas" && intern_data[i]["bd_field"] == "id_vivienda" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idVivienda = intern_data[i]["value"];
        break;
      }
    }
    if (idVivienda !== null) {
      setTimeout(() => {
        let idFA = this.functionsService.findFAWithTableField(1, "vivienda_comodin", "id", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, idVivienda);
        let idFABis = this.functionsService.findFAWithTableField(1, "vivienda", "id", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFABis, idVivienda);
        let idFA2 = this.functionsService.findFAWithTableField(1, "vivienda_comodin", "es_inmueble", structure["id_functional_parent_initial_dsb"]);
        console.log(idFA2, "idFA2idFA2idFA2")
        if (idFA2['tmp_value'] == 1) {
          this.functionsService.openSnackBar("Si no se indica ningún inmueble, se añadirá el inmueble de la Demanda", 4000, ["green-snackbar"]);
        }
      }, 500);
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase152(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    this.functionsService.dialog.closeAll();
    this.functionsService.updateResults(this.functionsService.getInternParam(structure, param), structure['id_functional_parent_initial']);
  }

  public functionCase153(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let idInteres = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "interes_cliente" && values[i]["field"] == "id") {
          idInteres = values[i]["value"];
          break;
        }
      }

      this.genericClientService.duplicarInteres(structure, idInteres);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase154(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let superficieTotal = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["value"] && values[i]["value"] > 0
          && (values[i]["field"] == "m2_planta_baja" || values[i]["field"] == "m2_sotano" || values[i]["field"] == "m2_planta1" || values[i]["field"] == "m2_planta2" || values[i]["field"] == "m2_planta3" || values[i]["field"] == "m2_planta4")) {
          superficieTotal = superficieTotal + +values[i]["value"];
        }
      }
  
      if (!isNaN(superficieTotal) && isFinite(superficieTotal)) {
        superficieTotal = Math.round((+superficieTotal + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = superficieTotal.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "vivienda", "m2_apublicar", structure["id_functional_parent_initial_dsb"], -1, false);
        console.log(superficieTotal, "superficieTotal", idFA)
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("La superfície total no es un número válido.");
      }
    });
  }

  public functionCase155(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe5c = 0;
      let importe10c = 0;
      let importe20c = 0;
      let importe50c = 0;
      let importe1e = 0;
      let importe2e = 0;
      let importe5e = 0;
      let importe10e = 0;
      let importe20e = 0;
      let importe50e = 0;
      let importe100e = 0;
      let importe200e = 0;
      let importe500e = 0;
      let importe_saldo_inicial = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe5c = values[i]["value"] * 0.05;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe10c = values[i]["value"] * 0.10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe20c = values[i]["value"] * 0.20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe50c = values[i]["value"] * 0.50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
          importe1e = values[i]["value"] * 1;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe2e = values[i]["value"] * 2;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe5e = values[i]["value"] * 5;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe10e = values[i]["value"] * 10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe20e = values[i]["value"] * 20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe50e = values[i]["value"] * 50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe100e = values[i]["value"] * 100;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe200e = values[i]["value"] * 200;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe500e = values[i]["value"] * 500;
        }
      }
  
      importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
      if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
        importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase156(structure, param, finished) {
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let allData = {
        paramInterns: this.functionsService.getInternParam(structure, param),
        actualValueFields: JSON.parse(JSON.stringify(this.functionsService.tmpArray)),
        dataAccount: this.functionsService.getDataAccount(),
        faWithQueriesToRefresh: structure["internal_routing_id_functional_area"],
        staticHTML: this.staticHTML,
      };
  
      this.functionsService.reloadTable(allData, finished);
    });
  }

  public functionCase157(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe_compraventa = 0;
      let importe_iva_itp = 0;
      let importe_hipoteca = 0;
      let gastos_estimados_cv = 0;
      let tipo_interes = 0;
      let plazo = 0;
      let cuota_estimada = 0;
      let cuota_gastos = 0;
      let ingresos_mensuales = 0;
      let importe_final1 = 0;
      let importe_final3 = 0;
      let importe_final4 = 0;
      let falta_compra = 0;
      let fondos_propios = 0;
      let valor_tasacion = 0;
      let importe_estimado = 0;
      let cobertura = 0;
      let aportacion = 0;
      let tasacion_minima = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "compra_venta_cv" && values[i]["value"] && values[i]["value"] > 0) {
          importe_compraventa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "impost_iva_itp" && values[i]["value"] && values[i]["value"] > 0) {
          importe_iva_itp = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "tipo_interes" && values[i]["value"] && values[i]["value"] > 0) {
          tipo_interes = values[i]["value"] / 12;
          tipo_interes = tipo_interes / 100;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "plazo_anos" && values[i]["value"] && values[i]["value"] > 0) {
          plazo = values[i]["value"] * 12;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "cuota_gastos" && values[i]["value"] && values[i]["value"] > 0) {
          cuota_gastos = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "ingresos_mensuales" && values[i]["value"] && values[i]["value"] > 0) {
          ingresos_mensuales = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "fondos_propios" && values[i]["value"] && values[i]["value"] > 0) {
          fondos_propios = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "id_encargo_cobertura_solicitada" && values[i]["value"] && values[i]["value"] > 0) {
          let idFA = this.functionsService.findFAWithTableField(values[i]["database"], values[i]["table"], values[i]["field"], structure["id_functional_parent_initial_dsb"]);
          for (let k of this.selectOptions[idFA['id_query']]) {
            if (values[i]["value"] == k['value'] && k['valor'] && k['valor'] > 0) {
              cobertura = +k['valor'];
              break;
            }
          }
        }
      }
  
      importe_estimado = (+importe_compraventa * (cobertura / 100));
      if (!isNaN(importe_estimado) && isFinite(importe_estimado)) {
        importe_estimado = Math.round((+importe_estimado + Number.EPSILON) * 100) / 100;
        importe_hipoteca = importe_estimado;
        let importeTotalDecimal7 = importe_estimado.toString().replace('.', ',');
        let idFA7 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "importe_estimado_hipoteca", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA7, importeTotalDecimal7);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
  
      importe_final1 = (importe_compraventa * importe_iva_itp) / 100 + 2500;
      if (!isNaN(importe_final1) && isFinite(importe_final1)) {
        importe_final1 = Math.round((+importe_final1 + Number.EPSILON) * 100) / 100;
        gastos_estimados_cv = importe_final1;
        let importeTotalDecimal1 = importe_final1.toString().replace('.', ',');
        let idFA1 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "gastos_estimados_cv", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA1, importeTotalDecimal1);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
  
      importe_final3 = importe_hipoteca * (tipo_interes * (1 + tipo_interes) ** plazo) / ((1 + tipo_interes) ** plazo - 1);
      if (!isNaN(importe_final3) && isFinite(importe_final3)) {
        importe_final3 = Math.round((+importe_final3 + Number.EPSILON) * 100) / 100;
        cuota_estimada = importe_final3;
        let importeTotalDecimal3 = importe_final3.toString().replace('.', ',');
        let idFA3 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "cuota_estimada", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA3, importeTotalDecimal3);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
      importe_final4 = ((+cuota_gastos + +cuota_estimada) / +ingresos_mensuales) * 100;
      if (!isNaN(importe_final4) && isFinite(importe_final4)) {
        importe_final4 = Math.round((+importe_final4 + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal4 = importe_final4.toString().replace('.', ',');
        let idFA4 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "ratio_endeudamiento", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA4, importeTotalDecimal4);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
      falta_compra = (+importe_compraventa + +gastos_estimados_cv - +importe_hipoteca - +fondos_propios);
      if (!isNaN(falta_compra) && isFinite(falta_compra)) {
        falta_compra = Math.round((+falta_compra + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal6 = falta_compra.toString().replace('.', ',');
        let idFA6 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "falta_compra", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA6, importeTotalDecimal6);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
      valor_tasacion = +importe_hipoteca * (80 / 100);
      if (!isNaN(valor_tasacion) && isFinite(valor_tasacion)) {
        valor_tasacion = Math.round((+valor_tasacion + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal5 = valor_tasacion.toString().replace('.', ',');
        let idFA5 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "ratio_tasacion", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA5, importeTotalDecimal5);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
      aportacion = +importe_compraventa + +gastos_estimados_cv - +importe_hipoteca;
      if (!isNaN(aportacion) && isFinite(aportacion)) {
        aportacion = Math.round((+aportacion + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal6 = aportacion.toString().replace('.', ',');
        let idFA5 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "aportacion", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA5, importeTotalDecimal6);
      } else {
        console.log("El importe total no es un número válido.");
      }
      tasacion_minima = +importe_hipoteca * (79 / 100);
      if (!isNaN(tasacion_minima) && isFinite(tasacion_minima)) {
        tasacion_minima = Math.round((+tasacion_minima + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal6 = tasacion_minima.toString().replace('.', ',');
        let idFA5 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo", "tasacion_minima", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA5, importeTotalDecimal6);
      } else {
        console.log("El importe total no es un número válido.");
      }
    }, 200);
  }
  
  public functionCase162(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe = 0;
      let descuento = 0;
      let tipo_descuento = 0;
      let importeTotal = 0
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importe = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
          descuento = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "id_descuento_unidad") {
          tipo_descuento = values[i]["value"];
        }
      }
  
      if (tipo_descuento == 1) {
        importeTotal = importe - descuento;
      } else if (tipo_descuento == 2) {
        importeTotal = importe - (importe * descuento / 100);
      } else {
        importeTotal = importe;
      }
      if (importeTotal < 0) importeTotal = 0;
      if (importeTotal >= 0) {
        importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "act_ludic_reservas_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotal);
      }
    });
  }

  public functionCase163(structure, param, finished) {
    let type = null; 
    let publicar = null;
    setTimeout(() => {
      if (this.functionsService.checkPreviewModeFunction(finished)) {
        return;
      }
      let structure_pre = this.functionsService.cloneVariable(structure);
      const v = this.functionsService.insertFormData(structure, param, finished, type, publicar);
      if (v != null) {
        v.subscribe((data) => {
          if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
            let inserts = data["response"];
            for (let i in inserts) {
              if (inserts[i]['bd_table'] == "act_ludic_reservas_tickets" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_ticket" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
                this.functionsService.addWindowParam(inserts[i]['responseId'], 1, "act_ludic_reservas_tickets", "id_ticket", structure['id_functional_parent_initial']);
                //this.addWindowParam(inserts[i]['responseId'], 1, "act_ludic_reservas_tickets", "id_ticket", structure['internal_routing_id_functional_area']);
                structure['refresh_params'] = 2;
                this.functionsService.openDialog(structure, 2);
                this.functionsService.openSnackBar("Ticket generado correctamente", 6000, ["green-snackbar"]);
                break;
              }
            }
          }
        });
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase164(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let cantidad = 0;
      let precio = 0;
      let importeTotal = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_servicios" && values[i]["field"] == "cantidad") {
          cantidad = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_servicios_precio" && values[i]["field"] == "precio") {
          precio = values[i]["value"];
        }
      }
  
      importeTotal = cantidad * precio;
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.functionsService.findFAWithTableField(1, "act_ludic_reservas_servicios", "importe", structure["id_functional_parent_initial_dsb"]);
      this.functionsService.assignValueFunctionalArea(idFA, importeTotal);
    });
  }

  public functionCase165(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importeDiferencia = 0;
      let importePagado = 0;
      let importeAPagar = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "comodin_a_pagar" && values[i]["value"] && values[i]["value"] > 0) {
          importeAPagar = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importePagado = importePagado + +values[i]["value"];
        }
      }
  
      importeDiferencia = importeAPagar - importePagado;
      if (!isNaN(importeDiferencia) && isFinite(importeDiferencia)) {
        importeDiferencia = Math.round((+importeDiferencia + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importeDiferencia.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "comodin_diferencia", "comodin_diferencia_importe", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase166(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importePendiente = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
          importePendiente = +values[i]["value"];
        }
      }
  
      if (!isNaN(importePendiente) && isFinite(importePendiente)) {
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "act_ludic_reservas_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let val = this.functionsService.cloneVariable(idFA["tmp_value"]).replace(',', '.')
        if (val) importePendiente = +importePendiente + +val;
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importePendiente.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      }
    });
  }

  public functionCase168(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.functionsService.genericFunctionRoutingStatic(structure, param, false, true, true);
    });
  }

  public functionCase169(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importeTotal = 0;
      let participantes = 0;
      let importeParticipante = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "importe_final" && values[i]["value"] && values[i]["value"] > 0) {
          importeTotal = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe_final" && values[i]["value"] && values[i]["value"] > 0) {
          importeTotal = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_act_ludic_reservas" && values[i]["field"] == "comodin_act_ludic_participantes" && values[i]["value"] && values[i]["value"] > 0) {
          participantes = values[i]["value"];
        }
      }
  
      importeParticipante = importeTotal / participantes;
      if (!isNaN(importeParticipante) && isFinite(importeParticipante)) {
        importeParticipante = Math.round((+importeParticipante + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importeParticipante.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "comodin_act_ludic_reservas", "comodin_act_ludic_total_participante", structure["id_functional_parent_initial_dsb"]);
        console.log("cooooo", idFA, importeParticipante);
        this.functionsService.assignValueFunctionalArea(idFA, importeParticipante);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase170(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe5c = 0;
      let importe10c = 0;
      let importe20c = 0;
      let importe50c = 0;
      let importe1e = 0;
      let importe2e = 0;
      let importe5e = 0;
      let importe10e = 0;
      let importe20e = 0;
      let importe50e = 0;
      let importe100e = 0;
      let importe200e = 0;
      let importe500e = 0;
      let importe_saldo_inicial = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe5c = values[i]["value"] * 0.05;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe10c = values[i]["value"] * 0.10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe20c = values[i]["value"] * 0.20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe50c = values[i]["value"] * 0.50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
          importe1e = values[i]["value"] * 1;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe2e = values[i]["value"] * 2;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe5e = values[i]["value"] * 5;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe10e = values[i]["value"] * 10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe20e = values[i]["value"] * 20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe50e = values[i]["value"] * 50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe100e = values[i]["value"] * 100;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe200e = values[i]["value"] * 200;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe500e = values[i]["value"] * 500;
        }
      }
  
      importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
      if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
        importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "act_ludic_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase171(structure, param, finished) {
    setTimeout(() => {
      let importe = null;
      if (this.staticHTML) {
        importe = this.authService.estanciasPriceCounter;
      } else {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let noches = 0;
        let precio = 0;
        let importeTotal = 0.00;
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_noches") {
            noches = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "precio_noche_estancia") {
            precio = values[i]["value"];
          }
        }
  
        importeTotal = noches * precio;
        importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
  
        console.log("HELOO HOTEL", importeTotal, noches, precio);
        importe = importeTotal.toFixed(2).toString().replace('.', ',');
      }
  
      let idFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "importe_total", structure["id_functional_parent_initial_dsb"]);
      this.functionsService.assignValueFunctionalArea(idFA, importe);
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase173(structure, param, finished) {
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let tableInsert = "act_ludic_cierres_caja";
      let importeTotal = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && (values[i]["table"] == "act_ludic_cierres_caja_payment_method" || values[i]["table"] == "act_ludic_cierres_caja_payment_method_snapshot") && values[i]["field"] == "importe_manual" && values[i]["value"] && values[i]["value"] > 0) {
          importeTotal = importeTotal + +values[i]["value"];
          if (values[i]["table"] == "act_ludic_cierres_caja_payment_method_snapshot") tableInsert = "act_ludic_cierres_caja_snapshot";
        }
      }
  
      if (!isNaN(importeTotal) && isFinite(importeTotal)) {
        importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importeTotal.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, tableInsert, "importe_manual", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase174(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;

    //Casos generals generic
    this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "incidencia-fin" + "/sth/" + idEmpresa;
    window.location.href = url;
    this.functionsService.finishFunction(finished);
  }

  public functionCase175(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 3, false, false);
  }

  public functionCase176(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.genericClientService.openContratoReserva(structure, param);
    });
  }

  public functionCase178(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.genericClientService.openContratoReserva(structure, param, 2);
    });
  }

  public functionCase179(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.genericClientService.openContratoReserva(structure, param, 3);
    });
  }

  public functionCase180(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let tipoVia = null;
      let nombreVia = null;
      let numeroVivienda = null;
      let bloqueVivienda = null;
      let escaleraVivienda = null;
      let plantaVivienda = null;
      let puertaVivienda = null;
      let idFAsDireccion = null;
      let intern_data = this.functionsService.getInternParam(structure, param);
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "tipo_via_comodin" && intern_data[i]["value"]) {
          tipoVia = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "nombre_via_comodin" && intern_data[i]["value"]) {
          nombreVia = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "numero_vivienda_comodin" && intern_data[i]["value"]) {
          numeroVivienda = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "bloque_vivienda_comodin" && intern_data[i]["value"]) {
          bloqueVivienda = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "escalera_vivienda_comodin" && intern_data[i]["value"]) {
          escaleraVivienda = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "planta_vivienda_comodin" && intern_data[i]["value"]) {
          plantaVivienda = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "puerta_vivienda_comodin" && intern_data[i]["value"]) {
          puertaVivienda = intern_data[i]["value"];
        }
      }
  
      let result = tipoVia + " " + nombreVia + " Nº " + numeroVivienda;
      if (bloqueVivienda != null) result += (" Bloq. " + bloqueVivienda);
      if (escaleraVivienda != null) result += (" Esc. " + escaleraVivienda);
      if (plantaVivienda != null) result += (" Plt. " + plantaVivienda);
      if (puertaVivienda != null) result += (" Pta. " + puertaVivienda);
  
      if (structure['internal_routing']) this.functionsService.findElementWithIdAndSetStatus(structure['internal_routing'], 2);
      //console.log("structure['internal_routing_id_functional_area']: ", structure['internal_routing_id_functional_area']);
      idFAsDireccion = structure['internal_routing_id_functional_area'].split(",");
      console.log("idFAsDireccion: ", idFAsDireccion);
      for (let i in idFAsDireccion) {
        let idFADir = this.functionsService.findElementWithId(idFAsDireccion[i], false, false, true);
        console.log("i: ", i, idFADir);
        if (idFADir) {
          if (structure['id_functional_parent_initial_dsb'] && structure['id_functional_parent_initial_dsb'] > 0 &&
            (!idFADir['id_functional_parent_initial_dsb'] || idFADir['id_functional_parent_initial_dsb'] == 0 || idFADir['id_functional_parent_initial_dsb'] != structure['id_functional_parent_initial_dsb'])) {
            this.functionsService.dialogRef.close(structure);
          }
          if (idFAsDireccion.length == 1 || !idFADir['tmp_value']) this.functionsService.assignValueFunctionalArea(idFADir, result);
        }
      }
    });
  }

  public functionCase181(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      setTimeout(() => {
        this.functionsService.reloadQueries(structure, param, finished, 1);
      });
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase182(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    let id = null;
    let idEmpresa = null;
    let idEmpresaOld = this.authService.empresaId;;
    let found = false;
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "vivienda" || values[i]["table"] == "clientes") && values[i]["field"] == "id_empresa") {
        idEmpresa = values[i]["value"];
      }
      if (structure["internal_routing"] == 1 && values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
        id = values[i]["value"];
      }
      if (structure["internal_routing"] == 2 && values[i]["database"] == 1 && values[i]["table"] == "clientes" && values[i]["field"] == "id") {
        id = values[i]["value"];
      }
      if (idEmpresa > 0 && id > 0) {
        found = true;
        break;
      }
    }

    if (found && structure["internal_routing"] < 3) {
      this.genericClientService.cambiarEmpresa(structure, id, idEmpresa, idEmpresaOld);
      this.functionsService.refreshStructure(0);
    } else {
      this.functionsService.snackBar.open("¡Vaya! Parece que ha habido un error al pasar los datos", "x", {
        duration: 6000,
        panelClass: ["red-snackbar"],
      });
    }
  }

  public functionCase183(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      var internal_fas = structure["internal_routing_id_functional_area"].replace(/\s+/g, "").split(",");
      setTimeout(() => {
        for (let idFA of internal_fas) {
          let fake = { 'child': this.functionsService.getAllStructures() };
          let copy_fields_idFA = this.functionsService.findFunctionalArea(fake, idFA);
          this.functionsService.assignValueFunctionalArea(copy_fields_idFA, structure['tmp_value']);
        }
      });
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase185(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe_total = 0;
      let pagas = 0;
      let importe = 0;
      let otros_ingresos = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_ingresos" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importe = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_ingresos" && values[i]["field"] == "pagas" && values[i]["value"] && values[i]["value"] > 0) {
          pagas = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_ingresos" && values[i]["field"] == "otros" && values[i]["value"] && values[i]["value"] > 0) {
          otros_ingresos = values[i]["value"];
        }
  
      }
      importe_total = (+importe * (pagas / 12) + +otros_ingresos);
      if (!isNaN(importe_total) && isFinite(importe_total)) {
        importe_total = Math.round((+importe_total + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal4 = importe_total.toString().replace('.', ',');
        let idFA4 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_ingresos", "total", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA4, importeTotalDecimal4);
      } else {
        console.log("El importe total no es un número válido.");
      }
    }, 200);
  }

  public functionCase186(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 4);
    this.functionsService.finishFunction(finished);
  }

  public functionCase187(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe_total_inicial = 0;
      let importe_total = 0;
      let plazo = 0;
      let importe_operacion = 0;
      let interes_inicial = 0;
      let interes_posterior = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "importe_solicitado" && values[i]["value"] && values[i]["value"] > 0) {
          importe_operacion = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "plazos" && values[i]["value"] && values[i]["value"] > 0) {
          plazo = values[i]["value"] * 12;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "interes_inicial" && values[i]["value"] && values[i]["value"] > 0) {
          interes_inicial = values[i]["value"] / 12;
          interes_inicial = interes_inicial / 100;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "interes_posterior" && values[i]["value"] && values[i]["value"] > 0) {
          interes_posterior = values[i]["value"] / 12;
          interes_posterior = interes_posterior / 100;
        }
  
      }
      importe_total_inicial = importe_operacion * (interes_inicial * (1 + interes_inicial) ** plazo) / ((1 + interes_inicial) ** plazo - 1);
      if (!isNaN(importe_total_inicial) && isFinite(importe_total_inicial)) {
        importe_total_inicial = Math.round((+importe_total_inicial + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_total_inicial.toString().replace('.', ',');
        let idFA3 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo_solicitudes_operaciones", "cuota_estimada_primer_periodo", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA3, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
  
      importe_total = importe_operacion * (interes_posterior * (1 + interes_posterior) ** plazo) / ((1 + interes_posterior) ** plazo - 1);
      if (!isNaN(importe_total) && isFinite(importe_total)) {
        importe_total = Math.round((+importe_total + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_total.toString().replace('.', ',');
        let idFA3 = this.functionsService.findFAWithTableField(1, "asesor_hipotecario_encargo_solicitudes_operaciones", "cuota_estimada_posterior", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA3, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    }, 200);
  }

  public functionCase188(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 5);
    this.functionsService.finishFunction(finished);
  }

  public functionCase189(structure, param, finished) {
    setTimeout(() => {
      let idFA = this.functionsService.findFAWithTableField(1, "empresa_usuarios_entradas", "fecha_fin", structure["id_functional_parent_initial_dsb"]);
  
      if (structure['tmp_value']) {
        let tmpValue = this.functionsService.cloneVariable(structure['tmp_value'])
        let newDate: Date = new Date(tmpValue);
        let newDateFormatted = this.functionsService.formatDate(newDate);
        if (this.functionsService.fecha_fin > newDateFormatted) {
          this.functionsService.assignValueFunctionalArea(idFA, newDateFormatted);
          idFA['myVal'] = this.functionsService.cloneVariable(newDateFormatted);
        }
        else {
          this.functionsService.assignValueFunctionalArea(idFA, this.functionsService.fecha_fin);
          idFA['myVal'] = this.functionsService.cloneVariable(this.functionsService.fecha_fin);
        }
      }
      else {
        this.functionsService.fecha_fin = new Date();
        this.functionsService.fecha_fin = this.functionsService.formatDate(this.functionsService.fecha_fin);
        this.functionsService.assignValueFunctionalArea(idFA, this.functionsService.fecha_fin);
        idFA['myVal'] = this.functionsService.cloneVariable(this.functionsService.fecha_fin);
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase190(structure, param, finished, val = null) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      this.functionsService.finishFunction(finished);
      setTimeout(() => {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let importePropina = 0;
        let found = false;
        let found2 = false;
        if (val == null) {
          for (let i in values) {
            if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
              importePropina = importePropina + +values[i]["value"];
              found = true;
            }
            if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "propina" && values[i]["value"]) {
              importePropina = importePropina - +values[i]["value"];
              found2 = true;
            }
            if (found && found2) break;
          }
        } else {
          importePropina = val;
        }
    
        if (!isNaN(importePropina) && isFinite(importePropina)) {
          importePropina = Math.round((+importePropina + Number.EPSILON) * -100) / 100;
          let importeTotalDecimal = importePropina.toString().replace('.', ',');
          let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets", "propina", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
        } else {
          console.log("El importe total no es un número válido.");
        }
      });
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase191(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 6);
    this.functionsService.finishFunction(finished);
  }

  public functionCase192(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 7);
    this.functionsService.finishFunction(finished);
  }

  public functionCase193(structure, param, finished) {
    this.genericClientService.openCashRest();
    this.functionsService.finishFunction(finished);
  }

  public functionCase194(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importe = values[i]["value"];
        }
      }
      if (importe >= 0) {
        let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importe);
      }
    });
  }

  public functionCase195(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 8);
    this.functionsService.finishFunction(finished);
  }

  public functionCase196(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idPedido = null;
    // Agafar num pedido
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
        break;
      }
    }
    if (idPedido > 0) {
      this.endpointClientService.marcharProductosPedido(idPedido).subscribe((data) => {
        this.functionsService.openSnackBar('¡Pedido marchado!', 3000);
        this.printTicketService.printCommandRestaurant(idPedido);
        //this.goPrev(true, null, false);
        this.functionsService.go("pedidos-actuales", true); // Esta hardcoded porque el goPrev hace cosas raras
      });
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún pedido", 4000, ["red-snackbar"]);
  }

  public functionCase197(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe_total = 0;
      let importePendiente = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"]) {
          importe_total = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_ticket_nuevo_importe" && values[i]["field"] == "comodin_nuevo_importe" && values[i]["value"]) {
          importePendiente = +values[i]["value"];
        }
      }
  
      if (!isNaN(importePendiente) && isFinite(importePendiente)) {
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let val = this.functionsService.cloneVariable(idFA["tmp_value"]).replace(',', '.')
        if (val) importePendiente = +importePendiente + +val;
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importePendiente.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
  
        let idFA2 = this.functionsService.findFAWithTableField(1, "comodin_ticket_nuevo_importe", "comodin_nuevo_importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        this.functionsService.assignValueFunctionalArea(idFA2, '');
      }
    });
  }

  public functionCase198(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idMovement = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_cierres_caja_movimientos" && intern_data[i]["bd_field"] == "id_movimiento" && intern_data[i]["value"]) {
        idMovement = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idMovement > 0) {
      this.printTicketService.printMovementRestaurant(idMovement);
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún movimiento a imprimir", 4000, ["red-snackbar"]);
  }

  public functionCase199(structure, param, finished) {
    const dt = new Date(param["date"]);
    return (dt.getFullYear() + "-" + (dt.getMonth() + 1 < 10 ? "0" : "") + (dt.getMonth() + 1) + "-" + (dt.getDate() < 10 ? "0" : "") + dt.getDate());
  }

  public functionCase200(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idCierre = 0;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_cierres_caja" && intern_data[i]["bd_field"] == "id_cierre_caja" && intern_data[i]["value"]) {
        idCierre = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idCierre > 0) {
      this.printTicketService.printCierreCajaRestaurant(idCierre);
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún cierre de caja a imprimir", 4000, ["red-snackbar"]);
  }

  public functionCase201(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idCierreCajaSnapshot = 0;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 &&
        intern_data[i]["bd_table"] == "rest_pedidos_cierres_caja_snapshot" &&
        intern_data[i]["bd_field"] == "id_cierre_caja_snapshot" &&
        intern_data[i]["value"]) {
        idCierreCajaSnapshot = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idCierreCajaSnapshot > 0) {
      this.printTicketService.printCierreCajaSnapshotRestaurant(idCierreCajaSnapshot);
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún cierre de caja a imprimir", 4000, ["red-snackbar"]);
  }

  public functionCase202(structure, param, finished) {
    setTimeout(() => {
      this.genericClientService.changeHideButton(structure);
    }, 200);
    this.functionsService.finishFunction(finished);
  }

  public functionCase203(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 9);
    this.functionsService.finishFunction(finished);
  }

  public functionCase204(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 10);
    this.functionsService.finishFunction(finished);
  }

  public functionCase205(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 11);
    this.functionsService.finishFunction(finished);
  }

  public functionCase206(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 12);
    this.functionsService.finishFunction(finished);
  }

  public functionCase207(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
    }, 1000);
  }

  public functionCase208(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
    }, 1000);
  }

  public functionCase209(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 13);
    this.functionsService.finishFunction(finished);
  }

  public functionCase210(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 14);
    this.functionsService.finishFunction(finished);
  }

  public functionCase212(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idPedido = null;
    let idProducto = null;
    let idOpcion = null;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_productos" && intern_data[i]["bd_field"] == "id_producto" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idProducto = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_productos" && intern_data[i]["bd_field"] == "id_opcion_comodin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idOpcion = intern_data[i]["value"];
      }
    }

    if (idOpcion && idOpcion != null && idOpcion > 0) {
      structure['id_function'] = 5;
      this.functionsService.executeNewFunction({structure: structure, param, finished});
    } else {
      if (idPedido && idPedido != null && idPedido > 0) {
        if (idProducto && idProducto != null && idProducto > 0) {
          this.endpointClientService.anadirProductoDirecto(idPedido, idProducto).subscribe((data) => {
            this.functionsService.updateResults(this.functionsService.getInternParam(structure, param), structure['id_functional_parent_initial']);
            this.printTicketService.printCommandRestaurant(idPedido);
          });
        } else this.functionsService.openSnackBar("No se ha encontrado ningún producto", 4000, ["red-snackbar"]);
      }
      else this.functionsService.openSnackBar("No se ha encontrado ningún pedido", 4000, ["red-snackbar"]);
    }
  }

  functionCase213(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idPedido = null;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
    }

    if (idPedido && idPedido != null && idPedido > 0) {
      this.endpointClientService.productosEstadoCincoDirecto(idPedido).subscribe((data) => {
        let struct2 = this.functionsService.cloneVariable(structure);
        struct2['id_function'] = 5;
        this.functionsService.updateResults(this.functionsService.getInternParam(structure, param), structure['id_functional_parent_initial']);
        this.functionsService.finishFunction(finished);
        this.functionsService.executeNewFunction({structure: struct2, param, finished});
      });
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún pedido", 4000, ["red-snackbar"]);
  }

  public functionCase214(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 15);
    this.functionsService.finishFunction(finished);
  }

  public functionCase215(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 16);
    this.functionsService.finishFunction(finished);
  }

  public functionCase216(structure, param, finished) {
    this.genericClientService.deleteAllPictures(structure, param);
    this.functionsService.finishFunction(finished);
  }

  public functionCase217(structure, param, finished) {
    setTimeout(() => {
      let intern_data = this.functionsService.getInternParam(structure, param);;
      console.log("intern_data ", intern_data);
      let idPedido = 0;
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido") {
          idPedido = intern_data[i]["value"];
          console.log("idPedido", idPedido);
          break;
        }
      }
      if (idPedido > 0) {
        this.printTicketService.printPedidoRestaurant(idPedido);
      }
      else this.functionsService.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["red-snackbar"]);
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase218(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let idCobro = null;
      let route = 'cobro-servicios';
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      console.log(intern_data, "intern_dataintern_data");
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "id_factura_recibo" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          idCobro = intern_data[i]["value"];
        }
      }
  
      //Casos generals generic
      this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + route + "/sth/" + idEmpresa + "-0&&" + idCobro;
      window.open(url);
    });
  }

  public functionCase219(structure, param, finished) {
    this.functionsService.deleteAllDocs(structure, param);
    this.functionsService.finishFunction(finished);
  }

  public functionCase220(structure, param, finished) {
    this.functionsService.downloadAllPictures(structure, param);
    this.functionsService.finishFunction(finished);
  }

  public functionCase222(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      this.functionsService.finishFunction(finished);
      setTimeout(() => {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let precio = 0;
        let precio_m2 = 0;
        let m2_construido = 0;
    
        let m2_construidosFounded = false;
        for (let i in values) {
          if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "eur_m2" && values[i]["value"] && values[i]["value"] > 0) {
            precio_m2 = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && (values[i]["field"] == "m2_apublicar" || values[i]["field"] == "m2_construidos") && values[i]["value"] && values[i]["value"] > 0) {
            m2_construido = values[i]["value"];
            if (values[i]["field"] == "m2_construidos") m2_construidosFounded = true;
          }
          if (precio_m2 != 0 && m2_construido != 0 && m2_construidosFounded) break;
        }
        console.log(m2_construido, precio_m2, "vprecio_m2precio_m2precio_m2");
    
        precio = +m2_construido * +precio_m2;
        if (!isNaN(precio) && isFinite(precio)) {
          precio = Math.round((+precio + Number.EPSILON) * 100) / 100;
          precio = precio;
          let importeTotalDecimal = precio.toString().replace('.', ',');
          let idFA7 = this.functionsService.findFAWithTableField(1, "vivienda", "precio", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA7, importeTotalDecimal);
        } else {
          console.log("El Precio del inmueble no es un número válido.");
        }
      });
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase224(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      setTimeout(() => {
        let idFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "precio_noche_estancia", structure["id_functional_parent_initial_dsb"]);
        let idFA2 = this.functionsService.findFAWithTableField(1, "hotel_reservas_comodin", "precio_noche_estancia", structure["id_functional_parent_initial_dsb"]);
        if (!idFA2['tmp_value'] || idFA2['tmp_value'] === null || idFA2['tmp_value'] == "" || idFA2['tmp_value'] == -1) return;
        if (idFA['tmp_value'] && idFA2['tmp_value'] && idFA['tmp_value'] == idFA2['tmp_value']) return;
        if (!idFA['tmp_value'] || idFA['tmp_value'] === null || idFA['tmp_value'] == "" || idFA['tmp_value'] == -1) {
          this.functionsService.assignValueFunctionalArea(idFA, this.functionsService.cloneVariable(idFA2['tmp_value']));
          idFA['last_value_added_function'] = this.functionsService.cloneVariable(idFA2['tmp_value']);
        } else {
          if (idFA['last_value_added_function'] == idFA['tmp_value']) {
            this.functionsService.assignValueFunctionalArea(idFA, this.functionsService.cloneVariable(idFA2['tmp_value']));
            idFA['last_value_added_function'] = this.functionsService.cloneVariable(idFA2['tmp_value']);
          } else {
            this.functionsService.openSnackBar("Precio/noche tendría que ser el que está asignado en la estancia (" + idFA2['tmp_value'] + "€), pero lo has modificado manualmente. Si tiene precio para el mes indicado anteriormente, pulsa el botón de Precio/noche", 12000, ["red-snackbar",]);
          }
        }
      });
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase225(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "movimientos-personal" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase226(structure, param, finished) {
    setTimeout(() => {
      let idFAInicio = this.functionsService.findFAWithTableField(1, "hotel_reservas", "fecha_entrada", structure["id_functional_parent_initial_dsb"]);
      let idFAFin = this.functionsService.findFAWithTableField(1, "hotel_reservas", "fecha_salida", structure["id_functional_parent_initial_dsb"]);
      let idFANumNoches = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_noches", structure["id_functional_parent_initial_dsb"]);
  
      if (idFAInicio['tmp_value'] && idFAInicio['tmp_value'] != null && idFAInicio['tmp_value'] != "") {
        this.functionsService.fecha_inicio = this.functionsService.formatDate(idFAInicio['tmp_value']);
      }
  
      if (idFAFin['tmp_value'] && idFAFin['tmp_value'] != null && idFAFin['tmp_value'] != "") {
        this.functionsService.fecha_fin = this.functionsService.formatDate(idFAFin['tmp_value']);
      }
  
      if (this.functionsService.fecha_inicio && this.functionsService.fecha_fin) {
  
        var fecha1 = new Date(this.functionsService.fecha_inicio);
        var fecha2 = new Date(this.functionsService.fecha_fin);
  
        var tiempo1 = new Date(fecha1.toDateString()).getTime();
        var tiempo2 = new Date(fecha2.toDateString()).getTime();
  
        var num_noches_milisegundos = tiempo2 - tiempo1;
        var num_noches = Math.floor(num_noches_milisegundos / (1000 * 60 * 60 * 24));
  
        if (num_noches < 0) {
          num_noches = 0;
          this.functionsService.openSnackBar("La 'Fecha entrada' es mayor que la 'Fecha salida'", 5000, ["red-snackbar"]);
        }
        this.functionsService.assignValueFunctionalArea(idFANumNoches, num_noches);
      }
      this.functionsService.reloadQueries(structure, param, finished);
    })
    this.functionsService.finishFunction(finished);
  }

  public functionCase227(structure, param, finished) {
    let userId = null;
    let pin = null;
    let intern_data = this.functionsService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_usuarios_entradas" && intern_data[i]["bd_field"] == "id_usuario" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        userId = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios_comodin" && intern_data[i]["bd_field"] == "pin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        pin = intern_data[i]["value"];
      }
    }
    if (userId != null && pin != null && userId != "" && pin != "") {
      this.functionsService.getPinWaiter(structure, param, finished, pin, userId);
      }
    this.functionsService.finishFunction(finished);
  }

  public functionCase228(structure, param, finished) {
    this.genericClientService.oneStepPayment(structure, param, finished, 6);
    this.functionsService.finishFunction(finished);
  }

  public functionCase229(structure, param, finished) {
    this.genericClientService.oneStepPayment(structure, param, finished);
    this.functionsService.finishFunction(finished);
  }

  public functionCase230(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let iva = null;
      let precioBase = null;
      let ivaCalculado = null;
      let idFAIvaCalculado = this.functionsService.findFAWithTableField(1, "facturas_recibos", "iva_calculado", structure["id_functional_parent_initial_dsb"]);
      let intern_data = this.functionsService.getInternParam(structure, param);
  
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "iva" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          iva = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "base" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          precioBase = intern_data[i]["value"];
        }
        if (iva != null && precioBase != null) {
          ivaCalculado = iva * precioBase / 100;
          this.functionsService.assignValueFunctionalArea(idFAIvaCalculado, ivaCalculado);
          break;
        }
      }
    });
  }

  public functionCase231(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let precioOportunidad = null;
      let idFAPrecioBaseFactura = this.functionsService.findFAWithTableField(1, "facturas_recibos", "base", structure["id_functional_parent_initial_dsb"]);
      let intern_data = this.functionsService.getInternParam(structure, param);
  
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes_oportunidades" && intern_data[i]["bd_field"] == "precio" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          precioOportunidad = intern_data[i]["value"];
          this.functionsService.assignValueFunctionalArea(idFAPrecioBaseFactura, precioOportunidad);
          break;
        }
      }
    });
  }

  public functionCase232(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let precioBase = null;
      let ivaCalculado = null;
      let idFAPrecioTotalFactura = this.functionsService.findFAWithTableField(1, "facturas_recibos", "total", structure["id_functional_parent_initial_dsb"]);
      let intern_data = this.functionsService.getInternParam(structure, param);
  
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "base" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          precioBase = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "iva_calculado" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          ivaCalculado = intern_data[i]["value"];
        }
        if (ivaCalculado != null && precioBase != null) {
          let total = ivaCalculado + precioBase;
          this.functionsService.assignValueFunctionalArea(idFAPrecioTotalFactura, total);
          break;
        }
      }
    });
  }

  public functionCase233(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let precioUnitario = null;
      let unidades = null;
      let iva = null;
      let ngForIndex = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "facturas_lineas" && values[i]["field"] == "precio_unitario" && values[i]["value"]) {
          precioUnitario = +values[i]["value"];
          ngForIndex = parseInt(values[i]['functionalArea'].split('.')[1]);
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "facturas_lineas" && values[i]["field"] == "iva" && values[i]["value"]) {
          iva = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "facturas_lineas" && values[i]["field"] == "unidades" && values[i]["value"]) {
          unidades = +values[i]["value"];
        }
      }
  
      if (!isNaN(precioUnitario) && isFinite(precioUnitario) && !isNaN(unidades) && isFinite(unidades) && !isNaN(iva) && isFinite(iva)) {
        precioUnitario = Math.round((+precioUnitario + Number.EPSILON) * 100) / 100;
        let precioFinal = precioUnitario * unidades + (precioUnitario * unidades * iva / 100);
        let idFA = this.functionsService.findFAWithTableField(1, "facturas_lineas", "precio", structure["id_functional_parent_initial_dsb"], ngForIndex);
        precioFinal = Math.round((+precioFinal + Number.EPSILON) * 100) / 100;
        let precioFinalDecimal = precioFinal.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, precioFinalDecimal);
      }
    });
  }

  public functionCase234(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "report-influencers" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase235(structure, param, finished) {
    this.genericClientService.deleteAllPictures(structure, param, 1);
    this.functionsService.finishFunction(finished);
  }

  public functionCase236(structure, param, finished) {
    setTimeout(() => {
      let idFAContent = this.functionsService.findFAWithTableField(1, "send_buffer_email", "content", structure["id_functional_parent_initial_dsb"]);
      let intern_data = this.functionsService.getInternParam(structure, param);
      let nombre = "";
      let apellidos = "";
      let nombre_form = "";
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes" && intern_data[i]["bd_field"] == "nombre" && intern_data[i]["value"]) {
          nombre = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes" && intern_data[i]["bd_field"] == "apellidos" && intern_data[i]["value"]) {
          apellidos = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "forms_mkt_empresas" && intern_data[i]["bd_field"] == "nombre" && intern_data[i]["value"]) {
          nombre_form = intern_data[i]["value"];
        }
      }
      if (nombre != null && apellidos != null) {
        let mensaje = "Hola, " + nombre + " " + apellidos + " ha contestado al formulario " + nombre_form + ".";
        this.functionsService.assignValueFunctionalArea(idFAContent, mensaje);
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase237(structure, param, finished) {
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe_propina = 0;
      let importe_total = 0;
      let importe_pago = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_importe_total" && values[i]["value"]) {
          importe_total = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_importe_pago" && values[i]["value"]) {
          importe_pago = +values[i]["value"];
        }
      }
  
      importe_propina = importe_pago - importe_total;
  
      if (!isNaN(importe_propina) && isFinite(importe_propina)) {
        if (importe_propina < 0) importe_propina = 0;
        importe_propina = Math.round((+importe_propina + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_propina.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets", "propina", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase238(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "report-historico-dias-por-semana" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase239(structure, param, finished) {
    this.genericClientService.copyLinkForm(structure, param);
  }

  public functionCase240(structure, param, finished) {
    this.genericClientService.copyLinkForm(structure, param, 1);
  }

  public functionCase241(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "control-descuentos" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase242(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let id_usuario = 0;
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios" && intern_data[i]["bd_field"] == "id" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            id_usuario = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "actividad-comercial" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + id_usuario + "&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }
  
  public functionCase243(structure, param, finished) {
    this.genericClientService.compartirInmueble(structure, param, finished);
  }

  public functionCase244(structure, param, finished) {
    this.genericClientService.compartirInmueble(structure, param, finished, 1);
  }

  public functionCase245(structure, param, finished) {
    setTimeout(() => {
      let idFATotal = this.functionsService.findFAWithTableField(1, "orders_cart_product", "total", structure["id_functional_parent_initial_dsb"]);
      let intern_data = this.functionsService.getInternParam(structure, param);
      let cantidad = 0;
      let precio = 0;
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "orders_cart_product" && intern_data[i]["bd_field"] == "quantity" && intern_data[i]["value"]) {
          cantidad = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "orders_cart_product" && intern_data[i]["bd_field"] == "price" && intern_data[i]["value"]) {
          precio = intern_data[i]["value"];
        }
      }
      if (cantidad != 0 && precio != 0) {
        this.functionsService.assignValueFunctionalArea(idFATotal, cantidad * precio);
      }
    });
  }

  public functionCase246(structure, param, finished) {
    let idLanguage = this.authService.labelLanguage;
    let nombreReport = structure["internal_routing"];
    let nombreEmpresa = this.authService.companyGenericName;
    let idEmpresa = this.authService.empresaId;
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    let actividad = null;
    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "actividades_calendario" &&
        values[i]["field"] == "id"
      ) {
        actividad = values[i]["value"];
        break;
      }
    }

    let url =
      "https://movin.cloud" +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "&&" +
      actividad
      ;

    window.open(url, "_blank");
    this.functionsService.finishFunction(finished);
  }

  public functionCase247(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    let idFAPrecioOportunidad = this.functionsService.findFAWithTableField(1, "clientes_oportunidades", "precio", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.functionsService.getInternParam(structure, param);
    let precio = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes_oportunidades_comodin" && intern_data[i]["bd_field"] == "precio_comodin" && intern_data[i]["value"]) {
        precio = intern_data[i]["value"];
        break;
      }
    }
    if (precio != 0) {
      this.functionsService.assignValueFunctionalArea(idFAPrecioOportunidad, precio);
    }
  }

  public functionCase248(structure, param, finished) {
    this.genericClientService.sendWhatsAppClienteContactar(structure, param, finished, 1);
  }

  public functionCase249(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
            console.log("ENTRO1: ", fecha_inicial);
  
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "report-historico-por-camarero" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase250(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.functionsService.addFieldToAnotherField(structure);
    });
  }

  public functionCase251(structure, param, finished) {
    let fas = structure['internal_routing'].split(",")
    for (let i in fas) {
      this.functionsService.finishFunction(finished);
      let splited = fas[i].split(".");
      let newId = splited[1];
      let id_functional_area = splited[1].toString().split(".")[0];
      let NGForIndex = -1;
      if (structure["ngForId"] != -1) {
        let elementContents = structure["id_functional_area"].toString().split(".");
        if (elementContents[1] > 0) NGForIndex = elementContents[1];
      }
      if (NGForIndex > 0) newId = this.functionsService.cloneVariable(newId) + "." + NGForIndex;  
      this.functionsService.findElementWithIdAndSetStatus(newId, splited[0]);
    }
  }

  public functionCase252(structure, param, finished) {
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let precio = 0;
      let margen = 1;
      let tipo = 0;
      let cantidadMargen = 0;
      console.log('valueees', values)
      for (let i in values) {
        let idFaSearch = this.functionsService.findElementWithId(values[i]["functionalArea"], false, false, true);
        if (idFaSearch !== null && idFaSearch['duplicate_id_functional_area'] && idFaSearch['duplicate_id_functional_area'] == structure['duplicate_id_functional_area']) {
          if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "precio" && values[i]["value"]) {
            precio = +values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "cantidad_margen" && values[i]["value"]) {
            margen = +values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "tipo" && values[i]["value"]) {
            tipo = +values[i]["value"];
          }
        }
      }
  
      if (!isNaN(precio) && isFinite(precio) && !isNaN(margen) && isFinite(margen)) {
        precio = Math.round((+precio + Number.EPSILON) * 100) / 100;
        let precioFinal = 0;
        if (tipo === 1) {//Valor absoluto
          precioFinal = precio + margen;
          cantidadMargen = margen;
        }
        else { //Porcentaje
          precioFinal = precio + (precio * (margen / 100));
          cantidadMargen = precio * (margen / 100);
        }
  
        let idFA = this.functionsService.findFAWithTableField(1, "gest_log_cotizaciones_lineas", "cantidad_margen_anadir", structure['id_functional_parent_initial_dsb'], structure['duplicate_id_functional_area']);
        cantidadMargen = Math.round((+cantidadMargen + Number.EPSILON) * 100) / 100;
        let cantidadMargenDecimal = cantidadMargen.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, cantidadMargenDecimal);
  
        let idFA2 = this.functionsService.findFAWithTableField(1, "gest_log_cotizaciones_lineas", "precio_final", structure['id_functional_parent_initial_dsb'], structure['duplicate_id_functional_area']);
        precioFinal = Math.round((+precioFinal + Number.EPSILON) * 100) / 100;
        let precioFinalDecimal = precioFinal.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA2, precioFinalDecimal);
  
  
        console.log(precio, margen, tipo, idFA, idFA2, "idFAidFAidFAidFA", cantidadMargenDecimal, precioFinalDecimal)
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase253(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let precioTotal = 0;
      let cantidadTotal = 0;
      let precioTotalFinal = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "precio" && values[i]["value"]) {
          precioTotal += +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "precio_final" && values[i]["value"]) {
          precioTotalFinal += +values[i]["value"];
        }
      }
  
      if (!isNaN(precioTotal) && isFinite(precioTotal) && !isNaN(precioTotalFinal) && isFinite(precioTotalFinal)) {
  
        let idFA = this.functionsService.findFAWithTableField(1, "gest_log_cotizaciones", "total_precio", 0);
        precioTotal = Math.round((+precioTotal + Number.EPSILON) * 100) / 100;
        let precioTotalDecimal = precioTotal.toString().replace('.', ',');
        console.log("idFA precio total: ", idFA, precioTotalDecimal);
        this.functionsService.assignValueFunctionalArea(idFA, precioTotalDecimal);
  
        let idFA2 = this.functionsService.findFAWithTableField(1, "gest_log_cotizaciones", "total_precio_final", 0);
        precioTotalFinal = Math.round((+precioTotalFinal + Number.EPSILON) * 100) / 100;
        let precioTotalFinalDecimal = precioTotalFinal.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA2, precioTotalFinalDecimal);
  
        let idFA3 = this.functionsService.findFAWithTableField(1, "gest_log_cotizaciones", "margen_total", 0);
        cantidadTotal = precioTotalFinal - precioTotal;
        cantidadTotal = Math.round((+cantidadTotal + Number.EPSILON) * 100) / 100;
        let cantidadTotalDecimal = cantidadTotal.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA3, cantidadTotalDecimal);
  
        let porcentajeMargenTotal = cantidadTotal * 100 / precioTotal;
        let idFA4 = this.functionsService.findFAWithTableField(1, "gest_log_cotizaciones", "porcentaje_margen_total", 0);
        porcentajeMargenTotal = Math.round((+porcentajeMargenTotal + Number.EPSILON) * 100) / 100;
        let porcentajeMargenTotalDecimal = porcentajeMargenTotal.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA4, porcentajeMargenTotalDecimal);
      }
    });
  }

  public functionCase254(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let url = this.genericClientService.buildURLFichaCotizacion(-1);
      window.open(url, "_blank");
      this.functionsService.finishFunction(finished);
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase255(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.functionsService.genericFunctionRoutingStatic(structure, param);
    });
  }

  public functionCase257(structure, param, finished) {
    this.functionsService.saveParamsClientOnly(structure, structure["id_functional_parent_initial"]);
    this.functionsService.route = structure["internal_routing"];
    this.functionsService.id_route = structure["internal_routing_id_functional_area"];
    this.routingService.previousModule = structure["id_functional_parent_initial"];
    this.functionsService.goRouting(this.functionsService.route, true);
    this.functionsService.finishFunction(finished);
  }

  public functionCase262(structure, param, finished) {
    let paramIntern = [];
    let intern_data = this.functionsService.getInternParam(structure, paramIntern);

    let email = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios" && intern_data[i]["bd_field"] == "email") {
        email = intern_data[i]["value"];
        break;
      }
    }
    this.functionsService.setPassword(null, email);
  }

  public functionCase267(structure, param, finished) {
    this.functionsService.vincularGoogle();
    //this.functionsService.finishFunction(finished);
  }

  public functionCase268(structure, param, finished) {
    this.functionsService.desvincularGoogle();
    //this.functionsService.finishFunction(finished);
  }

  public functionCase269(structure, param, finished) {
    this.functionsService.vincularMicrosoft();
  }

  public functionCase270(structure, param, finished) {
    this.functionsService.desvincularMicrosoft();
  }

  public functionCase272(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let idEstancia = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_estancias_tipo" && values[i]["field"] == "id_tipo") {
          idEstancia = values[i]["value"];
          break;
        }
      }

      this.endpointClientService.duplicarEstancia(idEstancia).subscribe(data => {
        this.functionsService.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["green-snackbar"],
        });
        console.log(structure, structure["internal_routing"] !== undefined, structure["internal_routing"] !== "", structure["internal_routing"] != null);
        if (structure["internal_routing"] !== undefined && structure["internal_routing"] !== "" && structure["internal_routing"] != null) {
          this.functionsService.addWindowParam(data["response"], 1, "hotel_estancias_tipo", "id_tipo", structure['id_functional_parent_initial'], true, "output");
          this.functionsService.route = structure["internal_routing"];
          this.functionsService.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.functionsService.goRouting(this.functionsService.route, false);
        } else {
          this.functionsService.addWindowParam(data["response"], 1, "hotel_estancias_tipo", "id_tipo", structure['id_functional_parent_initial'], true, "input");
          this.functionsService.refreshStructure();
        }
      })
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase273(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "informacion-reservas" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase274(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.removeTableRelationWhenValue(structure, param);
      this.functionsService.reloadQueries(structure, param, finished);
    });
  }

  public functionCase275(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let fecha_inicial = 0;
      let fecha_final = 0;
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;
      let intern_data = this.functionsService.getInternParam(structure, param);
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_inicial = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            fecha_final = intern_data[i]["value"];
          }
        }
  
        //Casos generals generic
        this.formsChanged[this.currentInitialArea["key"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
  
        let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "ocupacion-estancias" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
        window.open(url);
      }
    });
  }

  public functionCase276(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 99, false, false, 17);
  }

  public functionCase277(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    let numPersonas = null;
    let idUbicacion = null;
    let fecha = null;
    let hora = null;
    let idMesa = null;
    let idEmpresa = this.authService.getIdEmpresa();
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let intern_data = this.functionsService.getInternParam(structure, param);
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "num_personas" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          numPersonas = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "id_ubicacion" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          idUbicacion = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "fecha" && intern_data[i]["value"]) {
          fecha = this.functionsService.formatDate(this.functionsService.cloneVariable(intern_data[i]["value"]));
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "hora" && intern_data[i]["value"]) {
          hora = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "id_mesa" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          idMesa = intern_data[i]["value"];
        }
      }
      if (numPersonas != null && fecha != null && hora != null && idEmpresa != null) {
        if (idMesa != null) {
          this.endpointClientService.checkOcupacionMesaReservasRestaurante(idMesa, fecha, hora, idEmpresa).subscribe((data) => {
            this.functionsService.finishFunction(finished);

            console.log("responseee:", data["response"]["maximo_ocupantes"], data["response"]["hay_disponibilidad"]);

            if (data["response"]["maximo_ocupantes"] != null && data["response"]["maximo_ocupantes"] < numPersonas) {
              let restaOcupantes = numPersonas - data["response"]["maximo_ocupantes"];
              let errorMsg = "Hay " + restaOcupantes + " personas más de las que puede haber en la mesa";
              this.functionsService.openSnackBar(errorMsg, 10000, ["red-snackbar"]);
            }
            else if (data["response"]["hay_disponibilidad"] != null && data["response"]["hay_disponibilidad"] > 0) {
              let errorMsg = "La mesa no está disponible";
              this.functionsService.openSnackBar(errorMsg, 10000, ["red-snackbar"]);
            }
            else {
              this.functionsService.openSnackBar("¡Mesa disponible!", 3000);
            }
          });
        }
        else if (idUbicacion != null) {
          this.endpointClientService.checkOcupacionUbiReservasRestaurante(idUbicacion, fecha, hora, idEmpresa).subscribe((data) => {
            this.functionsService.finishFunction(finished);
            if (data["response"]["count_disponibilidad"] != null && data["response"]["count_disponibilidad"] < 0) {
              let restaOcupantes = -1 * data["response"]["count_disponibilidad"];
              let errorMsg = "Hay " + restaOcupantes + " personas más de las que puede haber en la ubicación " + data["response"]["nombre"];
              this.functionsService.openSnackBar(errorMsg, 10000, ["red-snackbar"]);
            } else {
              this.functionsService.openSnackBar("¡Hay disponibilidad para esta fecha!", 3000);
            }
          });
        }
      }
    }
  }

  public functionCase279(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      this.genericClientService.manageEstanciasHotelStatic(structure);
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase280(structure, param, finished) {
    this.functionsService.deleteDataDialog(structure, 5, finished);
  }

  public functionCase281(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let importeAGastarMaximo = null;
      let importeAGastar = null;
      let importeTicket = null;
      let esRest = false;
      let validReq = this.functionsService.formsArray(structure, param);
      if (validReq) {
        let intern_data = this.functionsService.getInternParam(structure, param);
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_tickets" && intern_data[i]["bd_field"] == "importe_final") {
            importeTicket = intern_data[i]["value"];
            esRest = true;
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "actividades_calendario_tickets" && intern_data[i]["bd_field"] == "importe_final") {
            importeTicket = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_tarjeta_regalo_clientes" && intern_data[i]["bd_field"] == "comodin_maximo_importe" && intern_data[i]["value"]) {
            importeAGastarMaximo = intern_data[i]["value"];
          }
          if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_tarjeta_regalo_clientes" && intern_data[i]["bd_field"] == "comodin_importe" && intern_data[i]["value"]) {
            importeAGastar = intern_data[i]["value"];
          }
        }
        let idFAImporteRestante = this.functionsService.findFAWithTableField(1, "tarjetas_regalo_clientes", "importe_restante", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let idFAImporteTicket = this.functionsService.findFAWithTableField(1, "actividades_calendario_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        if(esRest) {
          idFAImporteTicket = this.functionsService.findFAWithTableField(1, "rest_pedidos_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        }
        importeAGastar = this.functionsService.valueToInteger(importeAGastar);
        importeAGastarMaximo = this.functionsService.valueToInteger(importeAGastarMaximo);
        importeTicket = this.functionsService.valueToInteger(importeTicket);
        if (importeAGastar > importeAGastarMaximo) {
          this.functionsService.openSnackBar("El importe que quiere gastar es mayor al disponible", 4000, ["red-snackbar"]);
        } else if (importeAGastar > importeAGastarMaximo) {
          this.functionsService.openSnackBar("El importe que quiere gastar es mayor al disponible", 4000, ["red-snackbar"]);
        } else {
          let res = importeTicket - importeAGastar;
          if (res < 0) {
            this.functionsService.assignValueFunctionalArea(idFAImporteRestante, (importeAGastarMaximo - importeAGastar - res));
            this.functionsService.assignValueFunctionalArea(idFAImporteTicket, 0);
          }
          else {
            this.functionsService.assignValueFunctionalArea(idFAImporteRestante, (importeAGastarMaximo - importeAGastar));
            this.functionsService.assignValueFunctionalArea(idFAImporteTicket, res);
          }
        }
      }
    });
  }

  public functionCase282(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.calculateImportAndSetField(structure);
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase283(structure, param, finished) {
    setTimeout(() => {
      if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
        this.functionsService.calculateImportAndSetField(structure);
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase284(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.sumAllFieldsAndSetField(structure);
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase285(structure, param, finished) {
    setTimeout(() => {
      if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
        this.functionsService.sumAllFieldsAndSetField(structure);
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase286(structure, param, finished) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      let structureClone = this.functionsService.cloneVariable(structure);
      structureClone['id_function'] = 5;
      this.functionsService.executeNewFunction({structure: structureClone, param, finished});
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase287(structure, param, finished) {
    setTimeout(() => {
      let query = "2329." + structure['ngForIdIndex'];
      let results = this.selectOptions[query];
      if (results && results.length > 0) {
        let val = results[0];
        console.log(val);
        let idFAMinutos = this.functionsService.findFAWithTableField(1, "act_ludic_entradas_asistentes", "minutos", structure["id_functional_parent_initial_dsb"], structure["duplicate_id_functional_area"]);
        let idFARes = this.functionsService.findFAWithTableField(1, "act_ludic_entradas_asistentes", "importe", structure["id_functional_parent_initial_dsb"]);
        let importeTarifa = +(val['importe_tarifa']);
        let importeFracciones = +(val['importe_fracciones']);
        let res = importeTarifa;
  
        console.log(idFAMinutos['tmp_value'], val['minutos_tarifa'], idFAMinutos['tmp_value'] > val['minutos_tarifa']);
  
        if (idFAMinutos['tmp_value'] && val['minutos_tarifa'] && importeFracciones && val['minutos_tarifa'] &&
          val['minutos_adicionales'] && idFAMinutos['tmp_value'] > val['minutos_tarifa']) {
          console.log("importes: ", importeTarifa, (importeFracciones * ((idFAMinutos['tmp_value'] - val['minutos_tarifa']) / val['minutos_adicionales'])));
          res = importeTarifa + (importeFracciones * ((idFAMinutos['tmp_value'] - val['minutos_tarifa']) / val['minutos_adicionales']));
        }
        this.functionsService.assignValueFunctionalArea(idFARes, res);
      }
    });
    this.functionsService.finishFunction(finished);
  }

  public functionCase288(structure, param, finished) {
    this.routingService.shouldOpenSearch = true;
    this.functionsService.finishFunction(finished);
  }

  public functionCase289(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let idTarjeta = null;
      let paramIntern = [];
      let intern_data = this.functionsService.getInternParam(structure, paramIntern);
  
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "tarjetas_regalo" && intern_data[i]["bd_field"] == "id_tarjeta") {
          idTarjeta = intern_data[i]["value"];
          break;
        }
      }
      let idFA = this.functionsService.findFAWithTableField(1, "tarjetas_regalo_clientes", "id_tarjeta", structure["id_functional_parent_initial_dsb"]);
      this.functionsService.assignValueFunctionalArea(idFA, idTarjeta);
    });
  }

  public functionCase290(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe = 0;
      let descuento = 0;
      let tipo_descuento = 0;
      let importeTotal = 0
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importe = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
          descuento = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "id_descuento_unidad") {
          tipo_descuento = values[i]["value"];
        }
      }
  
      if (tipo_descuento == 1) {
        importeTotal = importe - descuento;
      } else if (tipo_descuento == 2) {
        importeTotal = importe - (importe * descuento / 100);
      } else {
        importeTotal = importe;
      }
      if (importeTotal < 0) importeTotal = 0;
      if (importeTotal >= 0) {
        importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotal);
      }
    });
  }

  public functionCase291(structure, param, finished, referesh = 0, type = null, publicar = null) {
    setTimeout(() => {
      this.functionsService.finishFunction(finished);
      if (this.functionsService.checkPreviewModeFunction(finished)) {
        return;
      }
      let structure_pre = this.functionsService.cloneVariable(structure);
      const v = this.functionsService.insertFormData(structure, param, finished, type, publicar);
      if (v != null) {
        v.subscribe((data) => {
          if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
            let inserts = data["response"];
            for (let i in inserts) {
              if (inserts[i]['bd_table'] == "actividades_calendario_tickets" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_ticket" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
                this.functionsService.addWindowParam(inserts[i]['responseId'], 1, "actividades_calendario_tickets", "id_ticket", structure['id_functional_parent_initial']);
                //this.addWindowParam(inserts[i]['responseId'], 1, "actividades_calendario_tickets", "id_ticket", structure['internal_routing_id_functional_area']);
                structure['refresh_params'] = 2;
                this.functionsService.openDialog(structure, 2);
                this.functionsService.openSnackBar("Ticket generado correctamente", 6000, ["green-snackbar"]);
                break;
              }
            }
          }
        });
      }
    });
  }

  public functionCase292(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe_total = 0;
      let importePendiente = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"]) {
          importe_total = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_ticket_nuevo_importe" && values[i]["field"] == "comodin_nuevo_importe" && values[i]["value"]) {
          importePendiente = +values[i]["value"];
        }
      }
  
      if (!isNaN(importePendiente) && isFinite(importePendiente)) {
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let val = this.functionsService.cloneVariable(idFA["tmp_value"]).replace(',', '.')
        if (val) importePendiente = +importePendiente + +val;
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importePendiente.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
  
        let idFA2 = this.functionsService.findFAWithTableField(1, "comodin_ticket_nuevo_importe", "comodin_nuevo_importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        this.functionsService.assignValueFunctionalArea(idFA2, '');
      }
    });
  }

  public functionCase293(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importeDiferencia = 0;
      let importePagado = 0;
      let importeAPagar = 0;
      let importePropina = 0;
      let indexNgForEfectivo = null;
      let hasEfectivo = false;
  
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets_payment_methods" && values[i]["field"] == "id_payment_method" && values[i]["value"] && values[i]["value"] > 0 && values[i]["value"] == 5) {
          let indexNgForEfectivoArray = values[i]["functionalArea"].split(".");
          indexNgForEfectivo = indexNgForEfectivoArray[indexNgForEfectivoArray.length - 1];
          break;
        }
      }
      console.log("values[i]]", values);
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "importe_final" && values[i]["value"]) {
          console.log("values[i]]", values[i]["value"]);
          importeAPagar = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
          importePagado = importePagado + +values[i]["value"];
          let indexNgForEfectivoArrayImporte = values[i]["functionalArea"].split(".");
          let indexNgForEfectivoImporte = indexNgForEfectivoArrayImporte[indexNgForEfectivoArrayImporte.length - 1];
          if (indexNgForEfectivo != null && indexNgForEfectivoImporte == indexNgForEfectivo) {
            hasEfectivo = true;
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "propina" && values[i]["value"] && values[i]["value"] > 0) {
          importePagado = importePagado - +values[i]["value"];
          importePropina = +values[i]["value"];
        }
      }
  
  
      importeDiferencia = importeAPagar - importePagado;
      if (!isNaN(importeDiferencia) && isFinite(importeDiferencia)) {
        importeDiferencia = Math.round((+importeDiferencia + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "comodin_box_propina", "comodin_box_propina", structure["id_functional_parent_initial_dsb"], -1, true, true);
        if (idFA !== null) {
          if (hasEfectivo) {
            idFA['id_functional_status_general'] = 2;
            if (importeDiferencia < 0 && !this.snackbarOpened) this.functionsService.openSnackBar('Hay dinero sobrante y no puedes marcar "Cobrado", pero no puedes añadir propina si tienes indicado "Efectivo". Debes retirar el efectivo primero.', 50000, ["red-snackbar"]);
            importePropina = 0;
            let idFA2 = this.functionsService.findFAWithTableField(1, "actividades_calendario_tickets", "propina", structure["id_functional_parent_initial_dsb"], -1, true);
            this.functionsService.assignValueFunctionalArea(idFA2, 0);
          } else {
            idFA['id_functional_status_general'] = 1
            this.functionsService.snackBar.dismiss();
          }
        }
        let importeTotalDecimal = importeDiferencia.toString().replace('.', ',');
        let idFA2 = this.functionsService.findFAWithTableField(1, "comodin_diferencia", "comodin_diferencia_importe", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA2, importeTotalDecimal);
        if (importePropina > 0 && importeDiferencia != 0 && !hasEfectivo) this.functionCase190(structure, param, finished, 0);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase295(structure, param, finished, val = null) {
    if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
      this.functionsService.finishFunction(finished);
      setTimeout(() => {
        let validReq = this.functionsService.formsArray(structure, param, false);
        let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
        let values = this.functionsService.cloneVariable(valuesOriginal);
        let importePropina = 0;
        let found = false;
        let found2 = false;
        if (val == null) {
          for (let i in values) {
            if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
              importePropina = importePropina + +values[i]["value"];
              found = true;
            }
            if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "propina" && values[i]["value"]) {
              importePropina = importePropina - +values[i]["value"];
              found2 = true;
            }
            if (found && found2) break;
          }
        } else {
          importePropina = val;
        }
    
        if (!isNaN(importePropina) && isFinite(importePropina)) {
          importePropina = Math.round((+importePropina + Number.EPSILON) * -100) / 100;
          let importeTotalDecimal = importePropina.toString().replace('.', ',');
          let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario_tickets", "propina", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
        } else {
          console.log("El importe total no es un número válido.");
        }
      });
    } else {
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase296(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importePendiente = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
          importePendiente = +values[i]["value"];
        }
      }
  
      if (!isNaN(importePendiente) && isFinite(importePendiente)) {
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let val = this.functionsService.cloneVariable(idFA["tmp_value"]).replace(',', '.')
        if (val) importePendiente = +importePendiente + +val;
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importePendiente.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      }
    });
  }

  public functionCase297(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe5c = 0;
      let importe10c = 0;
      let importe20c = 0;
      let importe50c = 0;
      let importe1e = 0;
      let importe2e = 0;
      let importe5e = 0;
      let importe10e = 0;
      let importe20e = 0;
      let importe50e = 0;
      let importe100e = 0;
      let importe200e = 0;
      let importe500e = 0;
      let importe_saldo_inicial = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe5c = values[i]["value"] * 0.05;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe10c = values[i]["value"] * 0.10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe20c = values[i]["value"] * 0.20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe50c = values[i]["value"] * 0.50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
          importe1e = values[i]["value"] * 1;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe2e = values[i]["value"] * 2;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe5e = values[i]["value"] * 5;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe10e = values[i]["value"] * 10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe20e = values[i]["value"] * 20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe50e = values[i]["value"] * 50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe100e = values[i]["value"] * 100;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe200e = values[i]["value"] * 200;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe500e = values[i]["value"] * 500;
        }
      }
      console.log("DANI 00: ", importe5c, importe10c, importe20c, importe50c, importe1e, importe2e, importe5e, importe10e, importe20e, importe50e, importe100e, importe200e, importe500e);
      importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
      if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
        importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "actividades_calendario_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase298(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.functionsService.concatFields(structure);
    });
  }

  public functionCase299(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.functionsService.genericFunctionRoutingStatic(structure, param, false, false, true);
    });
  }

  public functionCase300(structure, param, finished) {
    let intern_data = this.functionsService.getInternParam(structure, param);
    let idTicket = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "act_ludic_reservas_tickets" && intern_data[i]["bd_field"] == "id_ticket" && intern_data[i]["value"]) {
        idTicket = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idTicket > 0) {
      this.printTicketService.printTicketActLudic(idTicket);
    }
    else this.functionsService.openSnackBar("No se ha encontrado ningún ticket a imprimir", 4000, ["red-snackbar"]);
    this.functionsService.finishFunction(finished);
  }

  public functionCase301(structure, param, finished) {
    let FA = this.functionsService.findElementWithId(structure["internal_routing_id_functional_area"], false, false, true);
    console.log("FAAAA", FA);
    FA['showSub'] = true;
    if (FA['id_functional_status_general'] != 2) FA['id_functional_status_general_statusInitUpdate'] = this.functionsService.cloneVariable(FA['id_functional_status_general']);
    FA['id_functional_status_general'] = 2;
    this.functionsService.fecha_inicio_reservas = null;
    this.functionsService.fecha_fin_reservas = null;
    this.functionsService.hora_inicio_reservas = null;
    this.functionsService.hora_fin_reservas = null;
    let params = {intern: []};
    this.functionsService.findInterns(structure["idFAParamsInterns"], params, null, structure["id_functional_parent_initial_dsb"]);
    if(params && params['intern'] && params['intern'].length > 0) {
      let params2 = params['intern'];
      for(let i in params2) {
        if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_fecha_inicio_reserva' && params2[i]['value']) {
          this.functionsService.fecha_inicio_reservas = params2[i]['value'];
        }
        if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_hora_inicio_reserva' && params2[i]['value']) {
          this.functionsService.hora_inicio_reservas = params2[i]['value'];
        }
        if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_fecha_fin_reserva' && params2[i]['value']) {
          this.functionsService.fecha_fin_reservas = params2[i]['value'];
        }
        if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_hora_fin_reserva' && params2[i]['value']) {
          this.functionsService.hora_fin_reservas = params2[i]['value'];
        }
      }
      console.log(params2, "sdfsdfsdf", this.functionsService.fecha_inicio_reservas, this.functionsService.fecha_fin_reservas, this.functionsService.hora_inicio_reservas, this.functionsService.hora_fin_reservas);
    }
    setTimeout(() => {
      FA['id_functional_status_general'] = FA['id_functional_status_general_statusInitUpdate'];
      FA['wasInitialized'] = false;
      FA['showSub'] = false;
    }, 0);
    this.functionsService.finishFunction(finished);
  }

  public functionCase302(structure, param, finished) {
    // Menu
    this.routingService.shouldOpenMenu = true;
    this.functionsService.finishFunction(finished);
  }

  public functionCase303(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 0, false, false, 1, null, true);
  }

  public functionCase304(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importeTotal = 0;
      let meses = 0;
      let importeRenta = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_alquiler_reservas" && values[i]["field"] == "renta_total" && values[i]["value"] && values[i]["value"] > 0) {
          importeRenta = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_alquiler_reservas" && values[i]["field"] == "meses_alquiler_comodin" && values[i]["value"] && values[i]["value"] > 0) {
          meses = values[i]["value"];
        }
      }
  
      if (!isNaN(importeRenta) && isFinite(importeRenta)) {
        let porcentaje = 100;
        if (meses == 1 || meses == 2 || meses == 3) porcentaje = 60;
        if (meses == 4) porcentaje = 80;
        if (meses == 5) porcentaje = 100;
        if (meses > 5) porcentaje = 120;
        importeTotal = Math.round((+importeRenta) * porcentaje / 100);
        let importeTotalDecimal = importeTotal.toString().replace('.', ',');
        let idFA = this.functionsService.findFAWithTableField(1, "gest_alquiler_reservas", "honorarios", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase305(structure, param, finished) {
    if (this.functionsService.formsArray(structure, finished)) {      
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "usuarios" && values[i]["field"] == "id" && values[i]["value"] > 0) {
          localStorage.setItem('_i_', values[i]["value"]);
          localStorage.setItem('_in_dev_', "1");
          this.functionsService.refreshStructure(2);
          break;
        }
      }
    }
    this.functionsService.finishFunction(finished);
  }

  public functionCase306(structure, param, finished) {
    this.functionsService.insertUpdateForm(structure, param, finished, 4, false, false, null, null, true);
  }

  public functionCase307(structure, param, finished) {
    this.functionsService.finishFunction(finished);
    setTimeout(() => {
      this.functionsService.runClientScriptCron();
    });
  }
  
  public functionCase308(structure, param, finished) {
    let splited0Bar = structure["internal_routing_id_functional_area"].split("/");
    // El primer valor de la barra "/" es el campo que se busca para rellenar
    // El segundo valor de la barra "/" es el campo donde se guarda el valor en los params
    if (splited0Bar[1] !== undefined) {
      let splited0 = splited0Bar[0].split(".");
      let actualNgForId = this.functionsService.getNgForId(structure);
      let value = this.functionsService.findFAWithTableField(splited0[0], splited0[1], splited0[2], structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let splited1 = splited0Bar[1].split(".");
      this.functionsService.addWindowParam(value, splited1[0], splited1[1], splited1[2],structure['id_functional_parent_initial'], true, "output");
      this.functionsService.route = structure["internal_routing"];
      this.functionsService.id_route = this.functionsService.cloneVariable(value);
      this.routingService.previousModule = structure["id_functional_parent_initial"];
      this.functionsService.goRouting(structure["internal_routing"], true);
    }
  }

  public functionCase782(structure, param, finished) {
    let panelClass = [];
    if (this.routingService.shouldOpenExternalWindow)
      panelClass.push("overAllDialog");
    this.functionsService.dialog.open(DemoDialogComponent, {
      panelClass: panelClass,
    });
  }

  public functionCase783(structure, param, finished) {
    // Se llama estática desde el código de notis
    if (param && param !== null && param.actividad && param.actividad[0] && param.actividad[0].id && param.actividad[0].id > 0) {
      this.functionsService.addWindowParam(param.actividad[0].id, 1, "actividades_calendario", "id", 89);
      let x = { id_function: 5, internal_routing_id_functional_area: 34400, refresh_params: 2 };
      this.functionsService.executeNewFunction({structure: x, param: null, finished});
    } else {
      this.functionsService.openSnackBar("Actividad no encontrada", 5000, ["red-snackbar",]);
      this.functionsService.finishFunction(finished);
    }
  }

  public functionCase309(strucuture, param, finished) {
    this.functionsService.logout(strucuture['internal_routing']);
    this.functionsService.finishFunction(finished);
  }

  public functionCase310(structure, param, finished) {
    this.genericClientService.manageActividadesHotelStatic(structure);
    this.functionsService.finishFunction(finished);
  }

  public functionCase311(structure, param, finished) {
    this.genericClientService.checkAllPersonsHotelStatic(structure, param, finished);
    this.functionsService.finishFunction(finished);
  }

  public functionCase312(structure, param, finished) {
    this.functionsService.exeInsertAgain(structure, param, finished);
  }

  public functionCase313(structure, param, finished) {
    let idFA = this.functionsService.findFAWithTableField(1, "empresa_rrhh_fichajes", "fecha_fin", structure["id_functional_parent_initial_dsb"]);
    let now = new Date();
    this.functionsService.assignValueFunctionalArea(idFA, this.functionsService.formatDate(now));
    setTimeout(() => {
      this.functionCase8(structure, param, finished);
    }, 1000);
  }

  public functionCase314(structure, param, finished) {
    if(!this.functionsService.checkIfFAExists("1006.2")) { // Solo hay una estancia disponible
      setTimeout(() => {
        this.genericClientService.addAllPersonsHotelStatic(structure);
      });
    }
  }

  public functionCase316(structure, param, finished) {
    setTimeout(() => {
      this.genericClientService.ngForRelacionTipoCliente(structure, param);
      this.functionsService.finishFunction(finished);
    });
  }

  public functionCase317(structure, param, finished) {
    setTimeout(() => {
      this.functionsService.reloadQueries(structure, param, finished, 0, true);
      this.functionsService.finishFunction(finished);
    });
  }

  public functionCase318(structure, param, finished) {
    let idFAProducto = this.functionsService.findFAWithTableField(1, "hol_productos", "id_producto", structure["id_functional_parent_initial_dsb"]);
    let idFAAtributos = this.functionsService.findFAWithTableField(1, "hol_productos_atributos", "comodin_id_atributo", structure["id_functional_parent_initial_dsb"]);
    let idProducto = this.functionsService.cloneVariable(idFAProducto["tmp_value"]);
    let atributos = this.functionsService.cloneVariable(idFAAtributos["tmp_value"]);
    this.endpointClientService.createCombinationsHol(structure, param, finished, idProducto, atributos).subscribe((data) => {
      this.functionsService.finishFunction(finished);
      this.functionsService.openSnackBar("¡Combinación creada correctamente!", 3000);
    });
  }
}
